import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { CardBody } from "components/Nimbiv2/CardGeneral";
import { ToggleHeader } from "components/Nimbiv2/NAccordion";
import {
  AnonymousContainer,
  CardNimbi,
  DividerH,
  RowItem,
} from "components/Nimbiv2/styled";
import { Accordion, Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCopy, faPencil, faUser } from "@fortawesome/pro-regular-svg-icons";
import { ItemInfoPerson } from "components/Nimbiv2/PageStudent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { StepTitle } from "components/Nimbiv2/InitiativesComponent";
import ModalContactInfo from "containers/Task/detail/Modals/ModalContactInfo";
import { ContactInfo } from "models";
import { editPersonProfile } from "services/crmService";
import { updateTaskStudentInfo } from "store/actions/crm.actions";
import { updateStudentInfo } from "store/actions/student.actions";
import { fetchPersonsProfile } from "store/actions/async/crm-async.action";
import { useTranslation } from "react-i18next";
import { NSpinnerPoints } from "components/Nimbiv2";
import { Genders } from "utils/constants";
import copy from "copy-to-clipboard";
import { getGlobalConfig, verifyPermissionTab } from "utils";

const InfoStudent = ({ defaultActiveKey }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectMenu, setSelectMenu] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const { personsProfile } = useSelector((state) => state.crm);

  const {
    customInfoPerson,
    pending: { customInfoPerson: isPending },
  } = useSelector((state) => state.student);

  const [showModalContact, setShowModalContact] = useState(null);
  const [person, setPerson] = useState(null);
  const [isSaving, setSaving] = useState(false);

  useEffect(() => {
    if (personsProfile) {
      let contacts = [];

      if ("contactinfo_set" in personsProfile) {
        contacts = new ContactInfo(personsProfile.contactinfo_set).getList();
      }

      const auxProfile = { ...personsProfile, contactinfo_set: contacts };
      setPerson(auxProfile);
    }
  }, [personsProfile]);

  const updateStudent = ({ key, value }) => {
    const auxPerson = { ...person, [key]: value };
    setPerson(auxPerson);
  };

  const handleSaveChanges = async () => {
    const finalPerson = { ...person };
    let error = false;
    const contacts = [...finalPerson.contactinfo_set].map((c) => {
      if (c.error && !error) {
        error = true;
      }
      return c.deserialize();
    });
    if (!error) {
      finalPerson.contactinfo_set = contacts;

      setSaving(true);
      const payload = {
        studentId: finalPerson.id,
        payload: {
          first_name: finalPerson.first_name,
          last_name: finalPerson.last_name,
          //  birth_date: moment(studentBirthDate).format("YYYY-MM-DD"),
          gender: finalPerson.gender,
          contactinfo_set: finalPerson.contactinfo_set,
        },
      };

      const { status } = await editPersonProfile(payload);

      if (status === 200) {
        dispatch(updateTaskStudentInfo(finalPerson));
        dispatch(updateStudentInfo(finalPerson));
        setSaving(false);
        dispatch(fetchPersonsProfile(finalPerson.id));
        setShowModalContact(false);
        // notifyClose();
      } else {
        toast.error("Error!, no se pudo realizar la actualización");
      }
    } else {
      toast.error("Error en el formato ingresado");
    }
  };

  const dataStudent = useMemo(() => {
    const searchStudent = customInfoPerson.find(
      (item) => item.key_value === "person"
    );
    return searchStudent;
  }, [customInfoPerson]);

  useEffect(() => {
    setSelectMenu(null);
  }, [customInfoPerson]);

  const validateContent = (content) => {
    if (content?.length > 0) {
      const contentHasData = content.filter((item) => {
        let value = item?.value;
        let format = item?.format;
        if (format === "array") {
          return value?.length !== 0;
        }
        return !(
          !value &&
          ![0, 1].includes(value) &&
          (format !== "boolean" || typeof value === "undefined")
        );
      });

      return contentHasData?.length > 0;
    }
    return false;
  };

  return (
    <>
      {!isPending && (
        <CardNimbi radius="8px" padding="5px 20px">
          {person && (
            <ModalContactInfo
              show={showModalContact}
              contacts={person ? person.contactinfo_set : []}
              onHide={() => {
                setShowModalContact(false);
              }}
              onUpdateStudent={updateStudent}
              callSave={handleSaveChanges}
              isSaving={isSaving}
            />
          )}
          <AccordionDesign
            defaultActiveKey={defaultActiveKey}
            onSelect={(item) => {
              setSelectMenu(item);
            }}
          >
            {personsProfile && (
              <>
                <Card>
                  <ToggleHeader
                    eventKey="0"
                    title={_.upperFirst(t("personal_information_tab"))}
                    selectMenu={selectMenu}
                    icon={faUser}
                  />
                  <Accordion.Collapse eventKey="0">
                    <CardBody style={{ padding: "0px 0px 24px 52px" }}>
                      <RowItem>
                        {!verifyPermissionTab(
                          "hide_info_person",
                          "birth_date"
                        ) && (
                          <ItemInfoPerson
                            title={t("date_of_birth")}
                            detail={
                              personsProfile.birth_date
                                ? personsProfile.birth_date
                                : ""
                            }
                            style={{ marginTop: "12px" }}
                          />
                        )}
                        {!verifyPermissionTab("hide_info_person", "gender") && (
                          <ItemInfoPerson
                            title={_.upperFirst(t("gender"))}
                            detail={_.upperFirst(
                              t(_.get(Genders, personsProfile.gender, "other"))
                            )}
                            style={{ marginTop: "12px" }}
                          />
                        )}
                      </RowItem>
                      <RowItem>
                        {dataStudent &&
                          dataStudent.content
                            .filter((item) => item.label !== "is_active")
                            .map((itemText, keyText) => {
                              return (
                                <ItemInfoPerson
                                  key={keyText}
                                  title={itemText.label ?? "-"}
                                  detail={itemText.value}
                                  format={itemText.format}
                                  extension={itemText.extension}
                                  formatString={itemText.format_string}
                                  style={{ marginTop: "12px" }}
                                />
                              );
                            })}
                      </RowItem>
                      <RowItem
                        style={{
                          background: "#F8FBFF",
                          padding: "24px 3px",
                          marginTop: "12px",
                        }}
                      >
                        <Col
                          lg="12"
                          style={{ marginBottom: "19px" }}
                          className="d-flex justify-content-between"
                        >
                          <StepTitle style={{ fontWeight: "500" }}>
                            {t("contact_information")}
                          </StepTitle>
                          {!getGlobalConfig(
                            "disable_update_contact",
                            false
                          ) && (
                            <FontAwesomeIcon
                              icon={faPencil}
                              style={{ color: "#334FFF", cursor: "pointer" }}
                              onClick={() => {
                                setShowModalContact(true);
                              }}
                            />
                          )}
                        </Col>
                        <Col lg="6">
                          <strong>{_.upperFirst(t("mail"))}:</strong>
                          <div>
                            {personsProfile &&
                              personsProfile.contactinfo_set &&
                              personsProfile.contactinfo_set
                                .filter((item) => {
                                  return item.contact_type === "email";
                                })
                                .map((item, key) => {
                                  return (
                                    <div
                                      key={`${key}-email`}
                                      style={{ marginTop: "8px" }}
                                      className="item-description"
                                    >
                                      <AnonymousContainer
                                        isAnonymous={user.anonymous}
                                      >
                                        {item.value}
                                      </AnonymousContainer>
                                      {!user.anonymous && (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>{t("copy_email")}</Tooltip>
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            className="ml-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              copy(item.value);
                                              toast.info(
                                                `Copiado: ${item?.value}`
                                              );
                                            }}
                                          />
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  );
                                })}
                            {personsProfile &&
                              personsProfile.contactinfo_set &&
                              personsProfile.contactinfo_set.filter((item) => {
                                return item.contact_type === "email";
                              }).length === 0 && (
                                <div
                                  style={{ marginTop: "8px" }}
                                  className="item-description"
                                >
                                  {_.upperFirst(t("no_information"))}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col lg="6">
                          <strong>{_.upperFirst(t("phone"))}:</strong>
                          <div>
                            {personsProfile &&
                              personsProfile.contactinfo_set &&
                              personsProfile.contactinfo_set
                                .filter((item) => {
                                  return item.contact_type === "phone";
                                })
                                .map((item, key) => {
                                  return (
                                    <div
                                      key={`${key}-phone`}
                                      className="item-description"
                                      style={{ marginTop: "8px" }}
                                    >
                                      <AnonymousContainer
                                        isAnonymous={user.anonymous}
                                      >
                                        {item.value}
                                        {item.whatsapp_allowed && (
                                          <FontAwesomeIcon
                                            style={{
                                              marginLeft: "8px",
                                              color: "#00A38C",
                                              fontSize: "16px",
                                            }}
                                            icon={faWhatsapp}
                                          />
                                        )}
                                      </AnonymousContainer>
                                      {!user.anonymous && (
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip>{t("copy_phone")}</Tooltip>
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faCopy}
                                            className="ml-1"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              copy(item.value);
                                              toast.info(
                                                `Copiado: +${item?.value}`
                                              );
                                            }}
                                          />
                                        </OverlayTrigger>
                                      )}
                                    </div>
                                  );
                                })}

                            {personsProfile &&
                              personsProfile.contactinfo_set &&
                              personsProfile.contactinfo_set.filter((item) => {
                                return item.contact_type === "phone";
                              }).length === 0 && (
                                <div
                                  style={{ marginTop: "8px" }}
                                  className="item-description"
                                >
                                  {_.upperFirst(t("no_information"))}
                                </div>
                              )}
                          </div>
                        </Col>
                      </RowItem>
                    </CardBody>
                  </Accordion.Collapse>
                </Card>
              </>
            )}
            {customInfoPerson &&
              customInfoPerson
                .filter((item) => item.key_value !== "person")
                .map((item, key) => {
                  return (
                    <React.Fragment key={`d-${key}`}>
                      <DividerH key={`divider-${key}`} />
                      <Card
                        key={`card-dropdown-${key}`}
                        style={{ borderBottom: "0px" }}
                      >
                        <ToggleHeader
                          eventKey={item.key_value}
                          title={item.label}
                          selectMenu={selectMenu}
                          iconname={item.iconname}
                          childIcon={
                            !validateContent(item?.content) ? (
                              <div style={{ color: "#71819C" }}>
                                {_.upperFirst(t("no_information"))}
                              </div>
                            ) : null
                          }
                        />
                        <Accordion.Collapse eventKey={item.key_value}>
                          <CardBody style={{ padding: "0px 52px 24px 52px" }}>
                            <RowItem>
                              {item.content.map((itemText, keyText) => {
                                return (
                                  <ItemInfoPerson
                                    key={keyText}
                                    title={itemText.label ?? "-"}
                                    detail={itemText.value}
                                    format={itemText.format}
                                    extension={itemText.extension}
                                    formatString={itemText.format_string}
                                    style={{ marginTop: "12px" }}
                                    items={itemText.items ?? []}
                                  />
                                );
                              })}
                            </RowItem>
                          </CardBody>
                        </Accordion.Collapse>
                      </Card>
                    </React.Fragment>
                  );
                })}
          </AccordionDesign>
        </CardNimbi>
      )}
      {isPending && (
        <div>
          <NSpinnerPoints />
        </div>
      )}
    </>
  );
};
export default InfoStudent;

export const AccordionDesign = styled(Accordion)`
  .card {
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
  }
  .card-header {
    background-color: white;
    border-bottom: 0px;
    color: #2a2b2e;
    font-weight: 500;
    padding: 12px 20px 12px 0px;
  }
`;
