import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { ButtonSize } from "utils/constants";

const styleButton = {
  large: "padding: 11px 22px; fonts-size: 16px;",
  medium: "padding: 10px 20px; font-size: var(--font-size-caption);",
  small: "padding: 8px 16px; font-size: var(--font-size-button);",
  default: "",
};

const colors = {
  danger: {
    colorbutton: "var(--call-in-action-danger)",
    hoverbuttom: "var(--color-btn-danger-hover)",
  },
  second: {
    colorbutton: "var(--call-in-action-second)",
    hoverbuttom: "var(--color-btn-second)",
  },
  primary: {
    colorbutton: "var(--call-in-action)",
    hoverbuttom: "var(--color-btn-hover)",
  },
  default: {
    colorbutton: "var(--call-in-action)",
    hoverbuttom: "var(--color-btn-hover)",
  },
};

const ButtonN = ({
  icon,
  loading,
  size,
  children,
  positionIcon,
  sizeStyle,
  transparent,
  boxshadow,
  outlined,
  color,
  disabled = false,
  ...props
}) => {
  return (
    <ButtonNDesign
      {...props}
      sizestyle={sizeStyle}
      transparent={transparent ? 1 : 0}
      boxshadow={boxshadow ? 1 : 0}
      outlined={outlined ? 1 : 0}
      disabled={disabled}
      {...(color ? colors[color] : {})}
    >
      {loading && (
        <FontAwesomeIcon icon={faCircleNotch} className="fa-spin mr-icon" />
      )}
      {icon && positionIcon === "left" && !loading && (
        <span className="mr-icon">{icon}</span>
      )}
      {children}
      {icon && positionIcon === "right" && !loading && (
        <span className="ml-icon">{icon}</span>
      )}
    </ButtonNDesign>
  );
};

const ButtonNDesign = styled(Button)`
letter-spacing: 0.5px;
font-weight: bold;
  background-color: ${(props) =>
    props.outlined || props.transparent
      ? "transparent"
      : `${props.colorbutton}`};
  color: ${(props) =>
    props.outlined || props.transparent ? `${props.colorbutton}` : "white"};
  border-color: ${(props) => props.colorbutton};
  border-radius: ${(props) => (props.border ? props.border : ` 20px`)};

 ${(props) => _.get(styleButton, props.sizestyle ?? "default", "")}

  ${(props) =>
    props.outlined
      ? `&:hover {
    // background: ${props.hoverbuttom} !important;
    background: transparent !important;
    color: ${props.hoverbuttom} !important;
    border-color: ${props.hoverbuttom}  !important;
  } `
      : props.transparent === 1
      ? `&:hover {
        background: transparent !important;
        border-color: transparent !important;
        color: ${props.hoverbuttom} !important;
      }`
      : `&:hover {
        background: ${props.hoverbuttom} !important;
        border-color: transparent !important;
      }`}
  &:active {
    background: ${(props) => props.colorbutton} !important;
    border-color: ${(props) => props.colorbutton}) !important;
  }
  &:focus {
    background: ${(props) => props.colorbutton} !important;
    border-color: ${(props) => props.colorbutton} !important;
    box-shadow: 0 0 0 0.2rem ${(props) => props.hoverbuttom} !important;
  }
  ${(props) =>
    props.outlined
      ? `&:disabled {
        background-color: transparent !important;
        border-color: #D1D5E6 !important;
        color: #98A6BA;}`
      : `&:disabled {
        background-color: #D1D5E6 !important;
        border-color: #D1D5E6 !important;
        color: #98A6BA; }`}

  ${(props) =>
    props.boxshadow
      ? "box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);"
      : ""}
  
  .mr-icon {
    margin-right: 12px;
  }
  .ml-icon {
    margin-left: 12px;
  }
  ${(props) =>
    props.transparent === 1
      ? `background: transparent !important; border-color: transparent !important; text-decoration-line: underline; line-height: 140%; letter-spacing: 0.5px; 
      &:active {  background: transparent !important; color:${props.hoverbuttom} !important; }`
      : " "}
  ${(props) => (props.padding ? `padding: ${props.padding};` : " ")}
`;

ButtonN.defaultProps = {
  colorbutton: "var(--call-in-action)",
  hoverbuttom: "var(--color-btn-hover)",
  loading: false,
  positionIcon: "left",
  sizeStyle: ButtonSize.sm,
  transparent: false,
};
export default ButtonN;
