import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import FieldCompoment from "./FieldCompoment";

const NFormQuestion = ({
  data,
  onChange,
  isDisabled,
  filterset = {},
  transparent = false,
  position,
  clean = false,
  triggerNext,
  nextValidation = () => {},
}) => {
  const [arrayKeySelect, setArrayKeySelect] = useState([]);
  const [keyPosition, setKeyPosition] = useState(
    data.first_question
      ? data.first_question.toString()
      : Object.keys(data.questions)[0].toString()
  );
  const [arrayRender, setArrayRender] = useState([]);
  const [response, setResponse] = useState({});

  const nextQuestion = useCallback(() => {
    const question = arrayRender[position];
    if (question) {
      let endFormLocal = false;
      let new_array = [...arrayRender];
      const ids_response = Object.keys(response).map(
        (item) => response[item].question_id
      );
      if (!question.is_required) {
        if (!ids_response.includes(question.id)) {
          let new_response = { ...response };
          const res = {
            answer_id: null,
            answer_text: null,
            question_id: question.id,
            question_text: question.text,
            code: null,
            input_type: question.input_type,
          };
          new_response[position] = res;
          setResponse(new_response);
          if (question.question_default) {
            let new_key = question.question_default.toString();
            new_array.push(data.questions[new_key]);
            const new_array_filter = new_array.filter((item) => {
              return item.id.toString() === new_key;
            });
            if (new_array_filter.length === 1) {
              setArrayRender(new_array);
              setKeyPosition(new_key);
            }
          } else {
            setArrayRender(new_array);
            setKeyPosition(null);
            endFormLocal = true;
          }
        } else {
          if (
            arrayRender &&
            arrayRender.length === ids_response.length &&
            position + 1 === ids_response.length
          ) {
            endFormLocal = true;
          } else {
            endFormLocal = false;
          }
        }
        nextValidation(endFormLocal, response, question);
      } else {
        if (question) {
          let endF = new_array.length - 1 === position;
          nextValidation(endF, response, question);
        }
      }
    }
  }, [position, arrayRender, response, data.questions, nextValidation]);

  useEffect(() => {
    nextQuestion();
  }, [triggerNext]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let key = (
      data.first_question ?? Object.keys(data.questions)[0]
    ).toString();
    let new_array = [data.questions[key]];
    setArrayRender(new_array);
    setKeyPosition(key);
  }, [data.first_question, data.questions]);

  useEffect(() => {
    onChange(response);
  }, [response]); // eslint-disable-line react-hooks/exhaustive-deps

  const cleanResponse = useCallback(() => {
    setResponse({});
    setKeyPosition(
      data.first_question
        ? data.first_question.toString()
        : Object.keys(data.questions)[0].toString()
    );
    setArrayKeySelect([]);
  }, [data.first_question, data.questions]);

  useEffect(() => {
    if (clean) {
      cleanResponse();
    }
  }, [clean, cleanResponse]);

  const onChangeItem = (key, value, dataset, itemResponse) => {
    // Initial values
    let size = Object.keys(response).length;
    let new_array = [...arrayRender];
    let new_keys_selected = [...arrayKeySelect];
    let new_response = { ...response };
    let last_data = {};
    let item = null;
    // verify exists items
    if (arrayKeySelect.includes(dataset.id.toString())) {
      let position = arrayKeySelect.indexOf(dataset.id.toString());
      new_array = new_array.splice(0, position + 1);
      new_keys_selected = new_keys_selected.splice(0, position + 1);
      last_data = new_response[position];
      for (let i = position; i < size; i++) {
        delete new_response[i.toString()];
      }
      size = Object.keys(new_response).length;
      setArrayKeySelect(new_keys_selected);
    } else {
      new_keys_selected.push(keyPosition);
      setArrayKeySelect(new_keys_selected);
    }
    let data_response = {};
    if (dataset.input_type === "checkbox") {
      if (itemResponse && itemResponse.length > 0) {
        item = itemResponse[0];
      }
    } else {
      item = itemResponse;
    }

    // add next question
    if (item && item.question_child) {
      let new_key = item.question_child.toString();
      new_array.push(data.questions[new_key]);
      setArrayRender(new_array);
      setKeyPosition(new_key);
    } else if (dataset && dataset.question_default) {
      let new_key = dataset.question_default.toString();
      new_array.push(data.questions[new_key]);
      setArrayRender(new_array);
      setKeyPosition(new_key);
    } else {
      if (item) {
        setArrayRender(new_array);
        setKeyPosition(null);
      }
    }
    // ------------------

    if (dataset.input_type === "radio-button") {
      data_response = {
        answer_id: itemResponse.id,
        answer_text: itemResponse.text,
        question_id: dataset.id,
        question_text: dataset.text,
        code: itemResponse.code,
        input_type: dataset.input_type,
      };
    }
    if (dataset.input_type === "checkbox") {
      data_response = {
        answer_id: itemResponse.map((item) => item.id),
        answer_text: itemResponse,
        question_id: dataset.id,
        question_text: dataset.text,
        code: itemResponse
          .filter((item) => item.code !== null)
          .map((item) => item.code),
        input_type: dataset.input_type,
      };
    }
    if (dataset.input_type === "academic_progress") {
      delete itemResponse.question_child;
      data_response = {
        answer_id: null,
        answer_text:
          itemResponse && Object.keys(itemResponse).length > 0
            ? itemResponse
            : null,
        question_id: dataset.id,
        question_text: dataset.text,
        code: null,
        input_type: dataset.input_type,
      };
    }

    let position = searchPositionObject(new_response, dataset.id);
    if (position) {
      new_response[position] = {
        ...last_data,
        ...data_response,
      };
    } else {
      new_response[size.toString()] = {
        ...last_data,
        ...data_response,
      };
    }
    setResponse(new_response);
  };

  const searchPositionObject = (resp, question_id) => {
    const data = Object.keys(resp).filter(
      (item) => resp[item]["question_id"] === question_id
    );
    if (data.length > 0) {
      return data[0];
    }
    return null;
  };

  const onChangeComment = useCallback(
    (value, dataset) => {
      let new_response = { ...response };
      let size = Object.keys(response).length;
      let position = searchPositionObject(new_response, dataset.id);
      if (!arrayKeySelect.includes(dataset.id.toString())) {
        let new_keys_selected = [...arrayKeySelect];
        new_keys_selected.push(keyPosition);
        setArrayKeySelect(new_keys_selected);
      }
      if (
        ["only_comment", "academic_assistance"].includes(dataset.input_type)
      ) {
        let new_array = [...arrayRender];
        if (dataset && dataset.question_child) {
          let new_key = dataset.question_child.toString();
          new_array.push(data.questions[new_key]);
          setArrayRender(new_array);
          setKeyPosition(new_key);
        } else if (dataset && dataset.question_default) {
          let new_key = dataset.question_default.toString();
          new_array.push(data.questions[new_key]);
          setArrayRender(new_array);
          setKeyPosition(new_key);
        } else {
          setArrayRender(new_array);
          setKeyPosition(null);
        }
      }
      if (
        dataset.input_type === "checkbox" ||
        dataset.input_type === "academic_progress"
      ) {
        if (dataset.answers && dataset.answers.length > 0) {
          const first_answer = dataset.answers[0];
          let new_array = [...arrayRender];
          if (first_answer && first_answer.question_child) {
            let new_key = first_answer.question_child.toString();
            new_array.push(data.questions[new_key]);
            const new_array_filter = new_array.filter((item) => {
              return item.id.toString() === new_key;
            });
            if (new_array_filter.length === 1) {
              setArrayRender(new_array);
              setKeyPosition(new_key);
            }
          } else if (dataset && dataset.question_default) {
            let new_key = dataset.question_default.toString();
            new_array.push(data.questions[new_key]);
            setArrayRender(new_array);
            setKeyPosition(new_key);
          } else {
            setArrayRender(new_array);
            setKeyPosition(null);
          }
        }
      }
      if (position) {
        new_response[position] = {
          ...response[position],
          ...{
            question_id: dataset.id,
            question_text: dataset.text,
            question_comment: value,
            input_type: dataset.input_type,
          },
        };
      } else {
        new_response[size.toString()] = {
          question_id: dataset.id,
          question_text: dataset.text,
          question_comment: value,
          input_type: dataset.input_type,
        };
      }
      setResponse(new_response);
    },
    [response, arrayRender, data.questions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ContainerFilter transparent={transparent}>
      {/* <strong>{data.description}</strong> */}
      <div>
        {arrayRender.map((item, key) => {
          return (
            <FieldCompoment
              key={`field-${key}-${item.id}`}
              data={item}
              //filterset={filterset}
              onChange={onChangeItem}
              isDisabled={isDisabled}
              keyPosition={keyPosition}
              lastKeyPosition={
                arrayKeySelect.length > 0
                  ? arrayKeySelect[arrayKeySelect.length - 1]
                  : keyPosition
              }
              style={
                position === key ? { display: "block" } : { display: "none" }
              }
              show={position === key}
              onChangeComment={onChangeComment}
              response={
                position !== null && typeof position !== "undefined"
                  ? response[position.toString()]
                  : null
              }
            />
          );
        })}
      </div>
    </ContainerFilter>
  );
};

export default NFormQuestion;
NFormQuestion.propTypes = {
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const ContainerFilter = styled.div`
  ${(props) =>
    props.transparent
      ? `.form-control {
  background: transparent;
}`
      : ""}
`;
