import React from "react";
const NInfoDetail = ({ title, detail, marginTop, onClick, wrap }) => {
  return (
    <div style={{ marginTop: marginTop }}>
      <strong style={{ fontSize: "16px" }}>{title}</strong>
      <div
        style={{
          marginTop: "5px",
          fontSize: "14px",
          color: onClick ? "var(--call-in-action)" : "black",
          cursor: onClick ? "pointer" : "normal",
          overflowWrap: "anywhere",
        }}
        onClick={onClick ? onClick : () => {}}
      >
        {detail}
      </div>
    </div>
  );
};

export default NInfoDetail;
