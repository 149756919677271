export const GET_DASH_LIST = "GET_DASH_LIST";
export const SET_DASH_LIST = "SET_DASH_LIST";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const TOGGLE_PENDING = "TOGGLE_PENDING";
export const SET_STATS_DATA = "SET_STATS_DATA";
export const GET_STATS_DATA = "GET_STATS_DATA";
export const TOGGLE_SELECTED = "TOGGLE_SELECTED";
export const TOGGLE_COLLAPSE_TASK = "TOGGLE_COLLAPSE_TASK";
export const UPDATE_DASH_STUDENT_INFO = "UPDATE_DASH_STUDENT_INFO";
export const SET_INSIGHTS_TEAM = "SET_INSIGHTS_TEAM";
export const SET_INSIGHTS_OWN = "SET_INSIGHTS_OWN";
export const SET_INSIGHTS_RISKS = "SET_INSIGHTS_RISKS";
export const SET_INSIGHTS_PROGRESS = "SET_INSIGHTS_PROGRESS";
export const SET_TOP_CALLERS = "SET_TOP_CALLERS";
export const SET_INSIGHTS_TRACKING = "SET_INSIGHT_TRACKING";

export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});
export const getTaskList = (
  queryParams = { page: 1, page_size: process.env.REACT_APP_DEFAULT_PAGESIZE }
) => ({
  type: GET_DASH_LIST,
  payload: queryParams,
});
export const setList = (response) => ({
  type: SET_DASH_LIST,
  payload: response,
});
export const getStats = () => ({
  type: GET_STATS_DATA,
  payload: null,
});
export const setStats = (response) => ({
  type: SET_STATS_DATA,
  payload: response,
});
export const togglePending = ({ attr, sw }) => ({
  type: TOGGLE_PENDING,
  payload: { attr, sw },
});
export const toggleSelected = (sw) => ({ type: TOGGLE_SELECTED, payload: sw });
export const toggleCollapseTask = ({ taskId, sw }) => ({
  type: TOGGLE_COLLAPSE_TASK,
  payload: { taskId, sw },
});
export const updateTaskStudentInfo = (student) => ({
  type: UPDATE_DASH_STUDENT_INFO,
  payload: student,
});

export const setInsightsTeam = (response) => ({
  type: SET_INSIGHTS_TEAM,
  payload: response,
});

export const setInsightsTracking = (response) => ({
  type: SET_INSIGHTS_TRACKING,
  payload: response,
})

export const setInsightsOwn = (response) => ({
  type: SET_INSIGHTS_OWN,
  payload: response,
});
export const setInsightsRisks = (response) => ({
  type: SET_INSIGHTS_RISKS,
  payload: response,
});

export const setInsightsProgress = (response) => ({
  type: SET_INSIGHTS_PROGRESS,
  payload: response,
});

export const setTopCallers = (response) => ({
  type: SET_TOP_CALLERS,
  payload: response,
});
