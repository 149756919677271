import React, { useEffect } from "react";
import _ from "lodash";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleUp,
  faMinusCircle,
  faArrowCircleDown,
} from "@fortawesome/pro-regular-svg-icons";

import {
  AnonymousContainer,
  CardNimbi,
  NNameContrainerUnderline,
} from "components/Nimbiv2/styled";
import { NContainerFilters, NIcon, NTable } from "components/Nimbiv2";
import { fetchInitiativeStudentsTracking } from "store/actions/async/initiatives-async.actions";
import { useTranslationLocal } from "hooks";
import PaginationBar from "../PaginationBar";
import { getGlobalConfig } from "utils";
import { colorsBadge } from "utils/constants";
import { NBadge } from "components";
import { Highlighter } from "react-bootstrap-typeahead";
import { cleanCustomPerson } from "store/actions/student.actions";
import { cleanListCourses } from "store/actions/crm.actions";

const PageStudentTracking = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const dispatch = useDispatch();
  let history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const {
    list: students,
    pending: { list: isPending },
    paginationTracking,
    initiative,
  } = useSelector((state) => state.initiative);

  useEffect(() => {
    const payload = {
      source_type: initiative.source_type ?? "internal",
      page_size: 10,
      page: 1,
    };
    dispatch(fetchInitiativeStudentsTracking(id, payload));
  }, [dispatch, id, initiative]);

  const getScore = (item) => {
    if (item.last_risk_score <= 100 && item.last_risk_score >= 70) {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.danger}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("high"))} ${item.last_risk_score}%`}
          customStyle={{ borderRadius: "64px" }}
          big
        />
      );
    }
    if (item.last_risk_score < 70 && item.last_risk_score >= 40) {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.warning}
          icon={
            <FontAwesomeIcon
              icon={faMinusCircle}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("medium"))} ${item.last_risk_score}%`}
          customStyle={{ borderRadius: "64px" }}
          big
        />
      );
    }
    if (item.last_risk_score < 40 && item.last_risk_score >= 0) {
      return (
        <NBadge
          outlined
          newColor={colorsBadge.dark.success}
          icon={
            <FontAwesomeIcon
              icon={faArrowCircleDown}
              className="mr-1"
              style={{ fontSize: "11px" }}
            />
          }
          text={`${_.upperFirst(t("low"))} ${item.last_risk_score}%`}
          customStyle={{ borderRadius: "64px" }}
          big
        />
      );
    }
    return (
      <div className="d-flex justify-content-center">
        {_.upperFirst(t("no_information_line"))}
      </div>
    );
  };

  const getCoursesActive = (item) => {
    if (item.student_courses.length === 0) {
      return <div>{translation("message_not_have_courses_active")}</div>;
    }
    return (
      <p style={{ whiteSpace: "normal", textOverflow: "ellipsis" }}>
        {item.student_courses[0].name}{" "}
        {item.student_courses.length > 1 && (
          <span
            style={{
              background: "linear-gradient(0deg, #EDF1F7, #EDF1F7), #FFFFFF",
              borderRadius: "64px",
              padding: "2px 6px",
              fontSize: "10px",
              display: "inline-block",
            }}
          >
            <NIcon style={{ fontSize: "10px" }} iconname="plus" />{" "}
            {item.student_courses.length - 1}
          </span>
        )}
      </p>
    );
  };

  const goStudentPage = (item) => {
    dispatch(cleanCustomPerson());
    dispatch(cleanListCourses());
    history.push(`/alumnos/${item.id}/`);
  };

  const columns = [
    {
      title: translation("student"),
      dataIndex: "student",
      key: "student",
      width: "25%",
      render: (value, item) => {
        return (
          <div>
            {initiative.source_type === "internal" && (
              <NNameContrainerUnderline>
                <span
                  onClick={() => {
                    goStudentPage(item.student);
                  }}
                  style={{ cursor: "pointer", fontWeight: "bold" }}
                >
                  {!user.anonymous && item.student.full_name}
                  {user.anonymous && (
                    <>
                      {item.student.full_name.split(" ", 1)}{" "}
                      <AnonymousContainer
                        isAnonymous={user.anonymous}
                        radius="5px"
                      >
                        {item.student.full_name.split(" ").splice(1).join(" ")}
                      </AnonymousContainer>
                    </>
                  )}
                </span>
              </NNameContrainerUnderline>
            )}
            {initiative.source_type === "external" && (
              <strong>
                {!user.anonymous && item.student.full_name}
                {user.anonymous && (
                  <>
                    {item.student.full_name.split(" ", 1)}{" "}
                    <AnonymousContainer
                      isAnonymous={user.anonymous}
                      radius="5px"
                    >
                      {item.student.full_name.split(" ").splice(1).join(" ")}
                    </AnonymousContainer>
                  </>
                )}
              </strong>
            )}
            <div
              style={{ fontSize: "var(--font-size-small)", color: "#787C83" }}
            >
              <AnonymousContainer isAnonymous={user.anonymous}>{`${
                (item.student.document_type ?? "Other") === "Other"
                  ? translation("document_type_dynamic", "", t("other"))
                  : item.student.document_type ?? "-"
              } ${item.student.document_number}`}</AnonymousContainer>
            </div>
            {getGlobalConfig("id_student_show", "yes") === "yes" && (
              <div
                style={{
                  fontSize: "var(--font-size-small)",
                  color: "#787C83",
                  lineHeight: "120%",
                  marginTop: "2px",
                }}
              >
                <AnonymousContainer isAnonymous={user.anonymous}>
                  {item.student.external_id}
                </AnonymousContainer>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: translation("active_tracking_object"),
      dataIndex: "currentCourses",
      key: "currentCourses",
      width: "25%",
      render: (value, item) => {
        return <div>{getCoursesActive(item)}</div>;
      },
    },
    {
      title: translation("campus"),
      key: "sede",
      width: "24%",
      center: true,
      render: (item) => {
        return (
          <AnonymousContainer isAnonymous={user.anonymous}>
            <div style={{ textAlign: "center" }}> {item.campus_name}</div>
          </AnonymousContainer>
        );
      },
    },
    {
      title: translation("risk"),
      dataIndex: "risk",
      key: "risk",
      width: "15%",
      center: true,
      render: (value, item) => {
        return <div>{getScore(item)}</div>;
      },
    },
    /* {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (value, item) => {
        return (
          <NButton
            style={{ padding: "11px  15px", borderRadius: "6.85714px" }}
            onClick={() => {}}
          >
            <FontAwesomeIcon icon={faGraduationCap} />
          </NButton>
        );
      },
    },*/
  ];

  const customMenuItemDefault = (option, { text }) => {
    return (
      <div>
        <div>
          <Highlighter
            search={text}
          >{`${option?.student.full_name}`}</Highlighter>
        </div>
        <div>
          <small>ID: {option?.student?.external_id.toLocaleString()}</small>
        </div>
        <div>
          <small>
            RUT: {option?.student?.document_number.toLocaleString()}
          </small>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <NContainerFilters
          showButtonCreate={false}
          showFilters={false}
          placeholderSearch={translation(
            "placeholder_search_student",
            t("placeholder_search_student")
          )}
          urlSearch={`initiatives/student-tracking/${id}/?source_type=${initiative?.source_type}`}
          attribSearch="search"
          customMenuItem={customMenuItemDefault}
          compositeLabel={["student__document_number", "student__full_name"]}
          searchKey="student_id"
          persistentSearch={false}
          onChange={(data) => {
            if (data.hasOwnProperty("student_id")) {
              data["student_id"] = data["student_id"]?.id;
            }
            data["source_type"] = initiative?.source_type ?? "internal";
            dispatch(fetchInitiativeStudentsTracking(id, data));
          }}
        />
      </div>
      {!isPending && (
        <div style={{ fontSize: "var(--font-size-small)" }}>
          <strong>{paginationTracking ? paginationTracking.count : "0"}</strong>{" "}
          {translation("students", "", t("students"))}
        </div>
      )}

      <CardNimbi style={{ marginTop: "8px" }}>
        <NTable
          keyValue={"id"}
          columns={columns}
          dataSource={students}
          upperCaseHeader
          textEmpty={t("no_students_to_display", {
            students: translation("students", "", t("students")),
          })}
          isPending={isPending}
        />
      </CardNimbi>
      {!isPending && (
        <PaginationBar actionFun={fetchInitiativeStudentsTracking} id={id} />
      )}
    </div>
  );
};

export default PageStudentTracking;
