import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Menu, SidebarHeader, SidebarFooter } from "react-pro-sidebar";
import { useDefault } from "hooks";
import "react-pro-sidebar/dist/css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ConProSidebar,
  ConSidebarContent,
  ImageLogo,
  ContainerLogo,
  ContainerHeader,
  ButtonCloseSlide,
  ContainerSlide,
} from "./NSiderBar.styled";
import {
  faHomeAlt,
  faCheckSquare,
  faGraduationCap,
  faChartBar,
  faProjectDiagram,
  faChevronLeft,
  faChevronRight,
  faFlag,
  faQuestionCircle,
  faCog,
  // faList,
} from "@fortawesome/pro-regular-svg-icons";
// import {faCircleQuestion} from "@fortawesome/free-regular-svg-icons"
import {
  APP_MODULES,
  deleteLocalStorage,
  getDataFromLocalStorage,
  getDefaultFilters,
  setDataToLocalStorage,
  setSessionFilters,
  // verifyPermissionTab,
} from "utils";
import { logoNimbiWhite, logoNimbiWhiteCloud } from "assets/images";
import {
  toggleCollapsedSidebar,
  toggleSidebar,
  updateClientFilters,
} from "store/actions/site.actions";
import MenuItemNormal from "./MenuItemNormal";
import { getDefaultValue } from "hooks/useDefault";
import { cleanFiltersUser } from "utils/user";
import { useTranslation } from "react-i18next";
import { permissionRedirect } from "../../../routes/helpers";

const {
  DASHBOARD,
  TASK,
  STUDENT,
  REPORT,
  MOMENT,
  INITIATIVES,
  HELP,
  ADMINISTRATOR,
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
  // EXTERNAL_MANAGEMENT,
} = APP_MODULES;

const NSiderBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const myRef = useRef(null);
  const [hasScroll, setHasScroll] = useState(false);

  const { clientFilters } = useSelector((state) => state.site);
  const isOpened = useSelector((state) => state.site.isSidebarOpen);
  const dashboardMenuTitle = useDefault("dashboardMenuTitle", "Dashboard");
  const taskMenuTitle = useDefault("taskMenuTitle", _.upperFirst(t("tasks")));
  const studentMenuTitle = useDefault(
    "studentMenuTitle",
    _.upperFirst(t("students"))
  );
  const reportMenuTitle = useDefault(
    "reportMenuTitle",
    _.upperFirst(t("reports"))
  );
  const userMenuTitle = useDefault(
    "admMenuTitle",
    _.upperFirst(t("administration"))
  );
  const momentMenuTitle = useDefault("momentMenuTitle", t("action_plans"));
  const helpCenterTitle = useDefault("helpCenterTitle", t("help_center"));

  const initiative = useDefault("initiative", getDefaultValue("initiative"));

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const el = myRef.current;
    if (el) {
      setHasScroll(el.scrollHeight > el.clientHeight);
    }
  }, []);

  useEffect(() => {
    const data = getDataFromLocalStorage("siderbar", { collapsed: false });
    setCollapsed(data["collapsed"]);
    dispatch(toggleCollapsedSidebar(data["collapsed"]));
  }, [dispatch]);

  const changeCollapsed = () => {
    setCollapsed(!collapsed);
    setDataToLocalStorage("siderbar", { collapsed: !collapsed });
    dispatch(toggleCollapsedSidebar(!collapsed));
  };

  const goDashboard = () => {
    history.replace({
      pathname: permissionRedirect({
        userRoles: user ? user.groups : [],
      }),
    });
  };

  const renderLogo = () => {
    if (user && user.anonymous) {
      return (
        <h3
          onClick={goDashboard}
          style={{ overflow: "hidden", cursor: "pointer" }}
        >
          Nimbi
        </h3>
      );
    }

    if (user && user.client_logo) {
      return (
        <div
          onClick={goDashboard}
          style={{
            height: "48px",
            width: "48px",
            background: `url(${user.client_logo}) 0% 0% / cover`,
            borderRadius: "10px",
            cursor: "pointer",
            zIndex: "1",
            position: "relative",
          }}
        ></div>
      );
    }
    return (
      <h3
        onClick={goDashboard}
        style={{ overflow: "hidden", cursor: "pointer" }}
      >
        {user && user.client_area ? user.client_area : "Nimbi"}
      </h3>
    );
  };

  return (
    <>
      <ConProSidebar
        width={255}
        breakPoint="md"
        toggled={isOpened}
        onToggle={() => {
          dispatch(toggleSidebar(!isOpened));
        }}
        collapsed={collapsed}
      >
        <ButtonCloseSlide
          onClick={changeCollapsed}
          className="d-none d-md-flex"
          rightPosition={hasScroll ? "-1px" : "-16px"}
        >
          <FontAwesomeIcon
            className="font-size-caption"
            icon={!collapsed ? faChevronLeft : faChevronRight}
            style={{ overflow: "hidden" }}
          />
        </ButtonCloseSlide>
        {isOpened && (
          <ButtonCloseSlide
            onClick={() => {
              dispatch(toggleSidebar(!isOpened));
            }}
            className="d-flex d-md-none"
            rightPosition={hasScroll ? "-2px" : "-16px"}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="font-size-caption"
              style={{ overflow: "hidden" }}
            />
          </ButtonCloseSlide>
        )}
        <ContainerSlide ref={myRef}>
          <SidebarHeader>
            <ContainerHeader collapsed={collapsed}>
              <div className="d-flex">
                <div className="mr-auto">{renderLogo()}</div>
              </div>
            </ContainerHeader>
          </SidebarHeader>
          <ConSidebarContent collapsed={collapsed ? 1 : 0}>
            <Menu iconShape="square">
              <MenuItemNormal
                allowedCode="dashboard"
                collapsed={collapsed}
                url={DASHBOARD.URL}
                icon={faHomeAlt}
                title={dashboardMenuTitle}
              />
              <MenuItemNormal
                allowedCode="moment_list"
                collapsed={collapsed}
                url={MOMENT.URL}
                icon={faProjectDiagram}
                title={momentMenuTitle}
              />

              <MenuItemNormal
                allowedCode="initiatives_list"
                collapsed={collapsed}
                url={INITIATIVES.URL}
                icon={faFlag}
                title={initiative["menuTitle"] ?? ""}
                active={initiative["menuActive"] ?? true}
                onClick={() => {
                  deleteLocalStorage("tabInitiative");
                }}
              />

              <MenuItemNormal
                allowedCode="task_list"
                collapsed={collapsed}
                url={TASK.URL}
                icon={faCheckSquare}
                title={taskMenuTitle}
              />
              <MenuItemNormal
                allowedCode="student_list"
                collapsed={collapsed}
                url={STUDENT.URL}
                icon={faGraduationCap}
                title={studentMenuTitle}
                onClick={() => {
                  setSessionFilters(FILTER_STUDENT, {}, true);
                  const data = {
                    data: clientFilters.data,
                    filters: {
                      ...clientFilters.filters,
                      ...{
                        [APP_MODULES.STUDENT.ID]: getDefaultFilters("students"),
                      },
                    },
                  };
                  dispatch(updateClientFilters(data));
                }}
              />
              <MenuItemNormal
                allowedCode="report_list"
                collapsed={collapsed}
                url={REPORT.URL}
                icon={faChartBar}
                title={reportMenuTitle}
              />
              {/* {verifyPermissionTab("modules", "externalTask") && (
                <MenuItemNormal
                  allowedCode="external_management_list"
                  collapsed={collapsed}
                  url={EXTERNAL_MANAGEMENT.URL}
                  icon={faList}
                  title={"Gestiones externas"}
                />
              )} */}
              <MenuItemNormal
                allowedCode="user_list"
                collapsed={collapsed}
                url={ADMINISTRATOR.URL}
                icon={faCog}
                title={userMenuTitle}
                onClick={() => {
                  setSessionFilters(
                    APP_MODULES.USER.FILTER_NAME,
                    {
                      page: 1,
                      page_size: 10,
                    },
                    true
                  );
                  cleanFiltersUser();
                }}
              />
              <MenuItemNormal
                allowedCode="help_list"
                collapsed={collapsed}
                url={HELP.URL}
                icon={faQuestionCircle}
                title={helpCenterTitle}
              />
            </Menu>
          </ConSidebarContent>
          <SidebarFooter style={{ textAlign: "center" }}>
            <ContainerLogo
              className="sidebar-btn-wrapper"
              collapsed={collapsed}
            >
              <div>
                By
                <ImageLogo
                  src={collapsed ? logoNimbiWhiteCloud : logoNimbiWhite}
                  alt="logo"
                />
              </div>
            </ContainerLogo>
          </SidebarFooter>
        </ContainerSlide>
      </ConProSidebar>
    </>
  );
};

export default NSiderBar;
