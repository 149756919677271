/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Container,
  Form,
  Tabs,
  Tab,
  Collapse,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faFlag,
  faHourglassEnd,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { toast } from "react-toastify";
import {
  NSearchCampus,
  NSearchCourses,
  NSearchStudents,
  NSearchUsers,
} from "components";
import {
  loadClientFilters,
  updateClientFilters,
  setTempFilter,
} from "store/actions/site.actions";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import {
  toggleCheckedPage,
  toggleMarkItems,
} from "store/actions/student.actions";
import {
  runTemplateDispatcher,
  getTaskList,
} from "store/actions/async/crm-async.action";
import {
  fetchStudents,
  fetchStudentsInCourses,
} from "store/actions/async/student-async.actions";
import { fetchUsers } from "store/actions/async/user-async.actions";
import { getCoursesFromCampus } from "services/crmService";
import { advancedSearch } from "services/student.service";
import { usersAdvancedSearch } from "services/user.service";
import {
  APP_MODULES,
  setDataToLocalStorage,
  setSessionFilters,
  getDataFromLocalStorage,
  getSessionFilters,
  storeClientAllFilters,
  deleteLocalStorage,
} from "utils";
import taskStyles from "containers/Task/Task.module.css";
import studentStyles from "containers/Student/Student.module.css";
import userStyles from "containers/User/User.module.css";
import FormFilter from "components/NFormFilter";
import { RowItem } from "components/Nimbiv2/styled";
import styled from "styled-components";
import NFilterBadge from "components/Nimbiv2/NDynamicFilters/NFilterBadge";
import { useTranslation } from "react-i18next";
import ContainerFilters from "components/Nimbiv2/ContainerFilters";
import { useTranslationLocal } from "hooks";
const {
  STUDENT: { FILTER_NAME: FILTER_STUDENT },
  TASK: { FILTER_NAME: FILTER_TASK },
  USER: { FILTER_NAME: FILTER_USER },
} = APP_MODULES;

const SEARCH = {
  STUDENT: {
    FILTER_KEY: "external_id",
    FIELD_NAME: "external_id",
  },
  TASK: {
    FILTER_KEY: "student_tracking__student__external_id",
    FIELD_NAME: "external_id",
  },
  USER: {
    FILTER_KEY: "username",
    FIELD_NAME: "username",
  },
};

const TABKEY = {
  SEARCH: "search",
  FILTER: "filter",
};

const FilterBadge = ({ filterInfo }) => {
  if (Object.keys(filterInfo).length === 0) return null;
  return (
    <small className="px-3 py-1 global_badgeFilter">
      <span className="font-weight-bold">{filterInfo.key}: </span>
      <span className="">{filterInfo.value}</span>
    </small>
  );
};

const PriorityFilter = React.memo(({ isDisabled = false }) => {
  const dispatch = useDispatch();
  const [priorityTasks, setPriorityTasks] = useState(() => {
    const currentFilters = getDataFromLocalStorage("filters", {});
    if ("has_priority" in currentFilters) return currentFilters.has_priority;
    return false;
  });

  const [expirationOrder, setExpirationOrder] = useState(() => {
    const currentFilters = getDataFromLocalStorage("filters", {});
    if ("expiration_order" in currentFilters)
      return currentFilters.expiration_order;
    return false;
  });

  const handlePriorityChecked = useCallback(
    (event) => {
      setExpirationOrder(false);
      const isChecked = event.target.checked;
      setDataToLocalStorage("avoidReloadTasks", true);
      setSessionFilters(FILTER_TASK, {
        page: 1,
        has_priority: isChecked,
        expiration_order: false,
      });
      setPriorityTasks(isChecked);
      dispatch(getTaskList());
    },
    [dispatch]
  );

  const handleExpirationChecked = useCallback(
    (event) => {
      setPriorityTasks(false);
      const isChecked = event.target.checked;
      setDataToLocalStorage("avoidReloadTasks", true);
      setSessionFilters(FILTER_TASK, {
        page: 1,
        expiration_order: isChecked,
        has_priority: false,
      });
      setExpirationOrder(isChecked);
      dispatch(getTaskList());
    },
    [dispatch]
  );

  return (
    <>
      <div className="d-flex justify-content-start align-items-center pb-2">
        <Form.Check
          className="font-weight-bold"
          type="checkbox"
          label="Prioridad"
          id="check1"
          onChange={handlePriorityChecked}
          checked={priorityTasks}
          disabled={isDisabled}
          custom
        />
        <div className="mx-2">
          <FontAwesomeIcon className="icons_color" icon={faFlag} />
        </div>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <Form.Check
          className="font-weight-bold"
          type="checkbox"
          label="Expiración"
          id="check2"
          onChange={handleExpirationChecked}
          checked={expirationOrder}
          disabled={isDisabled}
          custom
        />
        <div className="mx-2">
          <FontAwesomeIcon className="icons_color" icon={faHourglassEnd} />
        </div>
      </div>
    </>
  );
});

const DynamicFilters = ({
  panel,
  onGetSingleStudent,
  onGetSuggestStudents,
  onLazySecondList,
  isPendingList = false,
  showPriorityFilter = false,
  filterNameLS = FILTER_TASK,
}) => {
  const dispatch = useDispatch();
  const { clientFilters } = useSelector((state) => state.site);
  const [tempFilters, setTempFilters] = useState({});
  const [expandFilters, setExpandFilters] = useState(false);

  const filtersData = useMemo(() => clientFilters.data[panel] || [], [
    clientFilters.data,
    panel,
  ]);

  const filtersCount = useMemo(() => {
    const currentFilters = Object.keys({ ...clientFilters.filters[panel] });
    let count = 0;
    count = currentFilters.length;
    return count;
  }, [clientFilters.filters, panel]);

  const filtersApplied = useMemo(() => {
    const currentFilters = Object.keys({ ...clientFilters.filters[panel] });
    const data = {};
    const allItems = {
      1: "SI",
      0: "NO",
      True: "SI",
      False: "NO",
      undefined: "NO",
    };
    currentFilters.forEach((filter) => {
      let filterKey;
      if (filter.includes("__")) {
        filterKey = filter.split("__")[1];
      } else {
        filterKey = filter;
      }
      // eslint-disable-next-line prefer-destructuring
      data[filterKey] = _.get(
        allItems,
        clientFilters.filters[panel][filter],
        clientFilters.filters[panel][filter]
      );
    });
    return data;
  }, [clientFilters.filters, panel]);

  const updateSessionFilters = useCallback(() => {
    const isEmpty = Object.keys(tempFilters).length === 0;
    if (isEmpty) return false;

    dispatch(updateClientFilters(tempFilters));

    const filters = Object.entries(tempFilters.filters[panel]);
    let i = 0;
    storeClientAllFilters(tempFilters.filters[panel], filterNameLS);
  }, [dispatch, tempFilters, panel, filterNameLS]);

  const handleFilterChanged = useCallback(
    (content) => {
      // content param contains:
      //    data -> selected option
      //    meta -> filter metada, ex: filterField to indicate filter_name
      if (!panel) return null;

      const {
        data,
        meta: { filterField },
      } = content;
      const {
        filters: { [panel]: allFilters = {} },
      } = clientFilters;
      const isEmptySelection = Object.keys(data).length === 0;
      const isEmptyFilters = Object.keys(allFilters).length === 0;
      let appliedFilters = [];

      if (!isEmptyFilters) {
        if (filterField in allFilters) {
          appliedFilters = [...allFilters[filterField]];
        }
      }

      if (isEmptySelection) {
        allFilters[filterField] = [];
      } else {
        allFilters[filterField] = [...appliedFilters, data];
      }

      setTempFilters({
        data: clientFilters.data,
        filters: { ...clientFilters.filters, [panel]: allFilters },
      });
    },
    [clientFilters, panel]
  );

  const handleFilterChanged2 = useCallback(
    (content) => {
      setTempFilters({
        data: clientFilters.data,
        filters: { ...clientFilters.filters, ...{ [panel]: content } },
      });
    },
    [clientFilters, panel]
  );

  const renderFilterGroup = useCallback(() => {
    if (filtersData.length === 0 || !panel) return null;
    const hasFilters = Object.keys(clientFilters.filters || {}).length > 0;
    const filtersetData = hasFilters ? clientFilters.filters[panel] : {};

    return (
      <>
        <Row className="mt-3">
          <Col>
            <div className={taskStyles.filterTitle}>Filtrar por:</div>
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <Container className="mt-2">
              <FormFilter
                data={filtersData}
                onChange={handleFilterChanged2}
                filterset={filtersetData ?? {}}
                isDisabled={isPendingList}
              />
            </Container>
          </Col>
        </Row>
      </>
    );
  }, [
    panel,
    filtersData,
    clientFilters.filters,
    isPendingList,
    handleFilterChanged2,
  ]);

  useEffect(() => {
    dispatch(loadClientFilters());
  }, [dispatch]);

  useEffect(() => {
    // Apply filters
    updateSessionFilters();
    onLazySecondList();
  }, [tempFilters, updateSessionFilters, onLazySecondList]);

  const renderFilterBadges = () => {
    if (Object.keys(filtersApplied).length === 0) return null;
    return Object.entries(filtersApplied).map((element) => {
      const [key, value] = element;
      return <FilterBadge key={key} filterInfo={{ key, value }} />;
    });
  };

  return (
    <Container className={taskStyles.filterBlock}>
      <Row style={{ padding: "0 15px" }} className="mt-2 mb-3">
        <Col xs="7">
          <NSearchStudents
            filterKey={SEARCH.TASK.FILTER_KEY}
            fieldName={SEARCH.TASK.FIELD_NAME}
            onChange={onGetSingleStudent}
            asyncSearch={onGetSuggestStudents}
            isDisabled={isPendingList}
          />
        </Col>
        <Col xs="2">
          {showPriorityFilter && panel === APP_MODULES.TASK.ID ? (
            <PriorityFilter isDisabled={isPendingList} />
          ) : null}
        </Col>
        <Col xs="3">
          {filtersData.length > 0 ? (
            <span
              className={`btn ${taskStyles.filterButton}`}
              onClick={() => {
                if (!isPendingList) setExpandFilters((sw) => !sw);
              }}
              aria-hidden="true"
            >
              {expandFilters ? "Ocultar" : "Mostrar"} Filtros Avanzados (
              {filtersCount}) &nbsp;&nbsp;
              <FontAwesomeIcon
                icon={expandFilters ? faChevronUp : faChevronDown}
              />
            </span>
          ) : null}
        </Col>
        <Row style={{ margin: 0 }} className="w-100">
          <Collapse in={expandFilters}>
            <Container>{renderFilterGroup()}</Container>
          </Collapse>
        </Row>
      </Row>
      {!expandFilters ? (
        <Row style={{ padding: "0 15px" }} className="mt-2 mb-3">
          <Col>{renderFilterBadges()}</Col>
        </Row>
      ) : null}
    </Container>
  );
};

export const StudentFilters = (props) => {
  const dispatch = useDispatch();
  const { _temp } = useSelector((state) => state.site);
  const { campus: listCampus } = useSelector((state) => state.crm);
  const {
    pagination,
    wholePageMarked,
    markAllItems,
    pending: { list: isPendingList },
  } = useSelector((state) => state.student);

  const [listCourses, setListCourses] = useState([]);
  const [campus, setCampus] = useState([]);
  const [lockActions, setLockActions] = useState(true);

  const fetchCoursesFromCampus = async (campusName) => {
    if (!campusName) return Promise.resolve([]);

    // get all courses from this campus
    const payload = { campus: campusName };
    const {
      data: { results: filteredCourses },
    } = await getCoursesFromCampus({
      queryParams: { page: 1, page_size: 1000 },
      payload,
    });

    return Promise.resolve(filteredCourses);
  };

  const handleFilterCampus = async (selected) => {
    const isEmpty = Object.keys(selected).length === 0;
    const campusValue = isEmpty ? [] : selected;

    // set temp filters for campus...
    const filters = {
      ..._temp,
      campus: isEmpty ? null : selected.campus,
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
    dispatch(setTempFilter(filters));
    dispatch(fetchStudents());
    setCampus(campusValue);

    // load courses for this campus
    const campusCourses = await fetchCoursesFromCampus(
      isEmpty ? null : selected.campus
    );
    setListCourses(campusCourses);

    // clear selections...
    dispatch(toggleMarkItems("markAllItems", false));
    dispatch(toggleMarkItems("wholePageMarked", false));
  };

  const handleFilterCourses = (courses) => {
    if (courses.length > 0) {
      setLockActions(false);
      const externalIds = Array.from(courses).map((course) =>
        Number(course.external_id)
      );
      const filters = { ..._temp, listCourses: externalIds };

      // update temp filter
      dispatch(setTempFilter(filters));

      // send request to the backend
      dispatch(fetchStudentsInCourses(externalIds));
    } else {
      setLockActions(true);
      dispatch(fetchStudents());
    }

    // clear selections...
    dispatch(toggleMarkItems("markAllItems", false));
    dispatch(toggleMarkItems("wholePageMarked", false));
  };

  const handleBtnAction = (payload) => {
    const finalPayload = {
      ...payload,
      apply_all: markAllItems,
      filters: {
        campus: _temp.campus,
        courses: _temp.listCourses ?? [],
      },
    };
    dispatch(runTemplateDispatcher(finalPayload));
    toast.success("La acción fue aplicada ✓");

    // clear selections...
    dispatch(toggleCheckedPage(false));
    dispatch(toggleMarkItems("markAllItems", false));
    dispatch(toggleMarkItems("wholePageMarked", false));
  };

  const tabSelected = (k) => {
    if (k === TABKEY.SEARCH) {
      //
    } else if (k === TABKEY.FILTER) {
      //
    }
  };

  const onSearchChange = (selected) => {
    setSessionFilters(APP_MODULES.STUDENT.FILTER_NAME, {
      _filteredList: false,
    });

    // fetch students whithout persisting the filters
    const query = selected[SEARCH.STUDENT.FIELD_NAME];
    dispatch(
      fetchStudents({
        page: 1,
        page_size: 10,
        [SEARCH.STUDENT.FILTER_KEY]: query ?? "",
      })
    );
  };

  const asyncSearchStudents = useCallback(async (query) => {
    const params = { page_size: 20, query: query.trim() };
    const response = await advancedSearch(params);
    let results;
    if (response.data) results = response.data.results;
    return Promise.resolve(results ?? []);
  }, []);

  return (
    <Row>
      <Col md={12}>
        <Tabs
          defaultActiveKey={TABKEY.SEARCH}
          id="filter-tabs"
          onSelect={tabSelected}
        >
          <Tab eventKey={TABKEY.SEARCH} title="Buscar">
            <div className={studentStyles.searchTab}>
              <span className={studentStyles.label}>Búsqueda de alumnos</span>
              <div style={{ width: "50%" }}>
                <NSearchStudents
                  filterKey={SEARCH.STUDENT.FILTER_KEY}
                  fieldName={SEARCH.STUDENT.FIELD_NAME}
                  onChange={onSearchChange}
                  isDisabled={isPendingList}
                  asyncSearch={asyncSearchStudents}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey={TABKEY.FILTER} title="Filtros">
            <div className={studentStyles.filterTab}>
              <NSearchCampus
                filterKey="studenttracking__campus__name"
                fieldName="campus"
                dataset={listCampus}
                onChange={handleFilterCampus}
                filterType={FILTER_STUDENT}
              />
              <div className={studentStyles.separator} />
              <NSearchCourses
                filterKey="studenttracking__tracking_object__external_id"
                fieldName="external_id"
                dataset={listCourses}
                onChange={handleFilterCourses}
                filterType={FILTER_STUDENT}
                isDisabled={campus.length === 0}
                searchType="campus_courses"
                isMultiple
                campusSelected={campus}
              />
            </div>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  );
};

export const UserFilters = (props) => {
  const USERS_SETTING = {
    FILTER_KEY: "filterSet_user",
    SEARCH_KEY: "searchUsers",
  };
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const {
    roles,
    pending: { roles: isPendingRoles },
  } = useSelector((state) => state.site);
  const {
    teams,
    pending: { team: isPendingTeam },
  } = useSelector((state) => state.team);
  const [openFilters, setOpenFilters] = useState(false);
  const { campus: CampusStudent, listCampus: Campuses } = useSelector(
    (state) => state.crm
  );
  const [filtersValue, setFiltersValues] = useState(
    JSON.parse(localStorage.getItem(USERS_SETTING.FILTER_KEY)) ?? {}
  );
  const {
    pending: { list: isPendingList },
    pagination,
  } = useSelector((state) => state.user);

  const getAllUsers = useCallback(
    (selected) => {
      let last_filter = getDataFromLocalStorage(FILTER_USER, {});

      // fetch users without persisting the filters
      const query = selected[SEARCH.USER.FIELD_NAME];
      let payload = {
        ...last_filter,
        page: 1,
        page_size: 10,
        [SEARCH.USER.FILTER_KEY]: query ?? "",
      };

      if (_.isEmpty(selected)) {
        deleteLocalStorage(USERS_SETTING.SEARCH_KEY);
        delete payload[SEARCH.USER.FILTER_KEY];
      } else {
        setDataToLocalStorage(USERS_SETTING.SEARCH_KEY, [selected]);
      }
      setSessionFilters(FILTER_USER, payload, true);
      dispatch(fetchUsers(payload));
    },
    [dispatch, USERS_SETTING.SEARCH_KEY]
  );

  const searchUsers = useCallback(async (query) => {
    const params = { page_size: 100, search: query.trim() };
    const response = await usersAdvancedSearch(params);
    let results;
    if (response.data) results = response.data.results;
    return Promise.resolve(results ?? []);
  }, []);

  const getAllResults = useCallback(() => {
    const queryParams = getSessionFilters(FILTER_USER, true);
    const payload = {
      ...queryParams,
    };
    dispatch(fetchUsers(payload));
  }, [dispatch]);

  const getFilters = useCallback(
    (valueParam) => {
      if (roles.length > 0) {
        let last_filter = getDataFromLocalStorage(FILTER_USER, {});
        let filters = { ...last_filter, ...valueParam };

        if (valueParam["groups"]) {
          let roleName = {};
          roles.forEach((item) => (roleName[item["name"]] = item["id"]));
          filters["groups"] = roleName[valueParam["groups"]];
        } else {
          delete filters["groups"];
        }
        if (valueParam["teams"]) {
          let teamName = {};
          teams.forEach((item) => (teamName[item["name"]] = item["id"]));
          filters["teams"] = teamName[valueParam["teams"]];
        } else {
          delete filters["teams"];
        }
        if (!valueParam["campus"]) {
          delete filters["campus"];
        } else {
          filters["campus"] = valueParam["campus"];
        }
        if (!valueParam["is_active"]) {
          delete filters["is_active"];
        } else {
          filters["is_active"] = valueParam["is_active"]["id"];
        }
        const payload = {
          page: 1,
          page_size: 10,
          ...filters,
        };
        localStorage.setItem(
          USERS_SETTING.FILTER_KEY,
          JSON.stringify(valueParam)
        );
        setSessionFilters(FILTER_USER, filters, true);
        setFiltersValues(valueParam);
        dispatch(fetchUsers(payload));
      }
    },
    [roles, teams, USERS_SETTING.FILTER_KEY, dispatch]
  );

  useEffect(() => {
    dispatch(fetchClientFilters({ module: APP_MODULES.USER.ID }));
  }, [dispatch]);

  const roleValues = roles.map((item) => item.name.toString()) ?? [];
  const roleTeams = teams.map((item) => item.name.toString()) ?? [];

  const deletefilter = useCallback(
    (filter) => {
      delete filtersValue[filter.key];
      getFilters(filtersValue);
    },
    [filtersValue, getFilters]
  );

  const renderFilterBadges = useCallback(() => {
    if (Object.keys(filtersValue).length === 0) return null;
    if (isPendingRoles || isPendingTeam) return null;
    const text = {
      groups: t("profiles"),
      teams: _.upperFirst(t("teams")),
      campus: _.upperFirst(translation("campus")),
      is_active: _.upperFirst(t("active")),
    };
    const keyValues = {
      is_active: "name",
    };
    return Object.keys(filtersValue).map((element, key) => {
      return (
        <NFilterBadge
          key={`key-filters-user-${key}`}
          filterInfo={{
            key: element,
            label: text[element],
            value: filtersValue[element],
            keyValue: keyValues[element],
          }}
          onClickDelete={deletefilter}
        />
      );
    });
  }, [filtersValue, isPendingRoles, isPendingTeam]); // eslint-disable-line react-hooks/exhaustive-deps

  const listCampus = useMemo(() => {
    return CampusStudent && CampusStudent.length > 0
      ? CampusStudent.map((item, key) => {
          return item.campus;
        })
      : Campuses
      ? Campuses.map((item, key) => {
          return item.name;
        })
      : [];
  }, [CampusStudent, Campuses]);

  return (
    <>
      <RowItem className="pt-3">
        <Col className={` ${taskStyles.tabContainer} px-0`} sm={6}>
          <InputDesign>
            <NSearchUsers
              filterKey={SEARCH.USER.FILTER_KEY}
              fieldName={SEARCH.USER.FIELD_NAME}
              onChange={getAllUsers}
              asyncSearch={searchUsers}
              isDisabled={isPendingList}
              isAsync
              persistText={USERS_SETTING.SEARCH_KEY}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{
                fontSize: "18px",
                marginLeft: "2px",
                color: "#334FFF",
              }}
            />
          </InputDesign>
        </Col>
        <Col
          sm={6}
          className="d-flex justify-content-end align-items-center p-0"
        >
          {props.children}
        </Col>

        <div style={{ marginTop: "16px" }} className="w-100">
          <ContainerFilters
            isPendingCount={isPendingList}
            countItems={pagination?.count}
            textCount={t("users_found")}
            hasFilters={true}
            renderFilterBadges={renderFilterBadges}
            renderFilterGroup={() => (
              <Container fluid style={{ padding: "0px", marginTop: "10px" }}>
                <FormFilter
                  isDisabled={isPendingList}
                  data={[
                    {
                      filter_field: "groups",
                      id: 5,
                      label: "Perfiles",
                      module: "users",
                      subtype_component: null,
                      type_component: "select",
                      values: roleValues,
                    },
                    {
                      filter_field: "teams",
                      id: 6,
                      label: "Equipos",
                      module: "users",
                      subtype_component: null,
                      type_component: "select",
                      values: roleTeams,
                    },
                    {
                      filter_field: "campus",
                      id: 7,
                      label: translation("campus"),
                      module: "users",
                      subtype_component: null,
                      type_component: "select",
                      values:
                        listCampus && listCampus.length > 0 ? listCampus : [],
                    },
                    {
                      filter_field: "is_active",
                      id: 8,
                      label: translation("active"),
                      module: "users",
                      subtype_component: null,
                      type_component: "selectObject",
                      labelItems: "name",
                      values: [
                        { name: "SI", id: "true" },
                        { name: "NO", id: "false" },
                      ],
                      compositeLabel: ["name"],
                    },
                  ]}
                  onChange={(value) => {
                    getFilters(value);
                  }}
                  filterset={filtersValue}
                ></FormFilter>
              </Container>
            )}
          />
        </div>
      </RowItem>
    </>
  );
};
const ButtonFilters = styled(Button)`
  background-color: var(--call-in-action);
  color: #fff;
  font-size: 0.8em;
  max-width: 220px;
  heigth: 28px;
`;
const InputDesign = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px 12px;
  .form-control {
    border: 0px solid #ced4da !important;
    padding: 0px;
  }
  .rbt {
    width: 100%;
  }
`;

DynamicFilters.propTypes = {
  onGetSingleStudent: PropTypes.func.isRequired,
  onGetSuggestStudents: PropTypes.func.isRequired,
  onLazySecondList: PropTypes.func.isRequired,
  panel: PropTypes.oneOf(["tasks", "students", "users"]),
  isPendingList: PropTypes.bool,
  showPriorityFilter: PropTypes.bool,
};

export { DynamicFilters };
