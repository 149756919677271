import React, { useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import {
  getInsightsOwn,
  getInsightsProgress,
  getInsightsRisks,
  getInsightsTeam,
  getInsightsTracking,
  getStats,
  getTopCallersList,
} from "store/actions/async/dashboard-async.actions";
import { getGlobalConfig } from "utils";
import CardDashboardFetch from "./CardDashboardFetch";
import styles from "./Dashboard.module.css";
import { CardRanking, CardTracking } from "./CustomCards";

const FilterBar = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    pending: {
      //stats: isPending,
      insightsTeam: insightsTeamPending,
      insightsOwn: insightsOwnPending,
      insightsRisks: insightsRisksPending,
      insightsProgress: insightsProgressPending,
      insightsTracking: insightsTrackingPending,
      topCallers: topCallersPending,
    },
    stats: { last_sync: lastSync },
    insightsTeam,
    insightsOwn,
    insightsRisks,
    insightsProgress,
    insightsTracking,
    topCallers,
  } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  const getLastSyncInfo = () => {
    if (!lastSync) return "";
    const formattedDate = moment(lastSync).format("DD/MM/YYYY");
    const relativeDate = moment(lastSync).locale(i18n.language).fromNow();
    return `${relativeDate} (${formattedDate})`;
  };

  const cards_default = getGlobalConfig("cards_dashboard", [
    "CardTeam",
    "CardOwn",
    "CardRisk",
  ]);

  const list_cards = {
    CardTeam: (key) => {
      return (
        <CardDashboardFetch
          fnFetchData={getInsightsTeam}
          item={insightsTeam}
          pending={insightsTeamPending}
          key={`card-${key}`}
        />
      );
    },
    CardTracking: (key) => {
      return (
        <CardDashboardFetch
          fnFetchData={getInsightsTracking}
          item={insightsTracking}
          pending={insightsTrackingPending}
          key={`card-${key}`}
          CustomCard={CardTracking}
        />
      );
    },
    CardOwn: (key) => {
      return (
        <CardDashboardFetch
          fnFetchData={getInsightsOwn}
          item={insightsOwn}
          pending={insightsOwnPending}
          key={`card-dashboard-${key}`}
        />
      );
    },
    CardRisk: (key) => {
      return (
        <CardDashboardFetch
          fnFetchData={getInsightsRisks}
          item={insightsRisks}
          pending={insightsRisksPending}
          key={`card-dashboard-${key}`}
        />
      );
    },
    CardProgress: (key) => {
      return (
        <CardDashboardFetch
          fnFetchData={getInsightsProgress}
          item={insightsProgress}
          pending={insightsProgressPending}
          key={`card-dashboard-${key}`}
        />
      );
    },
    CardRanking: (key) => {
      return (
        <CardDashboardFetch
          fnFetchData={getTopCallersList}
          item={topCallers}
          pending={topCallersPending}
          key={`card-dashboard-${key}`}
          CustomCard={CardRanking}
        />
      );
    },
  };

  return (
    <>
      <Row className={`pt-5 ${styles.widgetRow}`}>
        <Col className="px-4">
          <span className={styles.lastUpload}>
            {t("last_load")} {getLastSyncInfo()}
          </span>
        </Col>
      </Row>
      <Row className={`pt-2 pl-1 pr-1`}>
        {cards_default.map((item, key) => {
          return list_cards[item](key);
        })}
      </Row>
    </>
  );
};

export default FilterBar;
