import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StepTitle, TextTitle } from "components/Nimbiv2/InitiativesComponent";
import { DividerH, CardNimbi, RowItem } from "components/Nimbiv2/styled";
import { Col, Form } from "react-bootstrap";
import { ImageInitiatives2 } from "assets/images";
import { NIcon, NInputSearch, NButton } from "components/Nimbiv2";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "utils/constants";
import { useTranslationLocal } from "hooks";

const PageThird = ({
  onChange,
  formValues,
  onChangeForm,
  pageNumber,
  errors,
}) => {
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();
  const [listRadios, setListRadius] = useState([]);
  const [listRadiosTemp, setListRadiusTemp] = useState([]);
  const { templates } = useSelector((state) => state.crm);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedT, setSelectedT] = useState(null);

  const [search, setSearch] = useState("");

  const onClickCheck = (e, name, item) => {
    setSelectedT(item);
    setSelectedTemplate(e.currentTarget.value);
    if (formValues.optionInitiative === "Email") {
      onChangeForm("email_template", e.currentTarget.value, {
        name_template: name,
        template: item,
      });
    }
    if (formValues.optionInitiative === "Call") {
      onChangeForm("script_template", e.currentTarget.value, {
        name_template: name,
        template: item,
      });
    }
    if (formValues.optionInitiative === "WhatsApp") {
      onChangeForm("whatsapp_template", e.currentTarget.value, {
        name_template: name,
        template: item,
      });
    }
  };

  useEffect(() => {
    if (formValues) {
      if (formValues.optionInitiative === "Email") {
        setListRadius(templates["email"]);
        setListRadiusTemp(templates["email"]);
        if (formValues.email_template) {
          setSelectedT(formValues.template);
          setSelectedTemplate(formValues.email_template);
        }
      }
      if (formValues.optionInitiative === "Call") {
        setListRadius(templates["call"]);
        setListRadiusTemp(templates["call"]);
        if (formValues.script_template) {
          setSelectedT(formValues.template);
          setSelectedTemplate(formValues.script_template);
        }
      }
      if (formValues.optionInitiative === "WhatsApp") {
        let templates_whatsapp = [];
        if (templates["whatsapp"]) {
          templates_whatsapp = templates["whatsapp"].filter(
            (item) => formValues.source_type === item.parameters_from
          );
        }
        setListRadius(templates_whatsapp);
        setListRadiusTemp(templates_whatsapp);
        if (formValues.whatsapp_template) {
          setSelectedT(formValues.template);
          setSelectedTemplate(formValues.whatsapp_template);
        }
      }
    }
  }, [formValues, templates]);

  const onChangeSearch = (e) => {
    let newListRadios = listRadiosTemp.filter((x) =>
      x.name.toLowerCase().includes(e.target.value)
    );
    setSearch(e.target.value);
    setListRadius(newListRadios);
  };

  return (
    <div style={{ maxWidth: "922px", margin: "auto", paddingBottom: "50px" }}>
      <CardNimbi padding="0px">
        <div style={{ padding: "33px 35px 0px 35px" }}>
          <RowItem>
            <Col xs="8" style={{ paddingLeft: "0px" }}>
              <StepTitle>
                {pageNumber ? pageNumber : t("step_number", { number: "4" })}
              </StepTitle>
              <div style={{ marginTop: "16px" }}>
                <TextTitle>
                  {formValues.optionInitiative === "Email" &&
                    t("title_select_template_email_initiative", {
                      students: translation("students", "", t("students")),
                    })}
                  {formValues.optionInitiative === "Call" &&
                    t("title_select_template_call_initiative", {
                      students: translation("students", "", t("students")),
                    })}
                </TextTitle>
              </div>
              <DividerH style={{ marginTop: "9px" }} />
            </Col>
          </RowItem>
        </div>
        <RowItem>
          <Col xs="5" style={{ paddingLeft: "0px" }}>
            <div
              style={{
                background: "#F8FBFF",
                minHeight: "486px",
                height: "100%",
                padding: "28px 25px 28px 35px",
                borderBottomLeftRadius: "8px",
              }}
            >
              <NInputSearch
                value={search}
                onChange={onChangeSearch}
                placeholder={t("find_template")}
              />
              <div style={{ marginTop: "16px" }}>
                <TitleRadioButton style={{ textTransform: "uppercase" }}>
                  {t("templates")}
                </TitleRadioButton>
              </div>

              {errors.hasOwnProperty("required_select_template") && (
                <div style={{ marginTop: "15px" }}>
                  <div style={{ color: "#E23232" }}>
                    * {errors["required_select_template"]}
                    <NIcon
                      iconname="exclamationCircle"
                      style={{ marginLeft: "4px" }}
                    ></NIcon>
                  </div>
                </div>
              )}

              <div
                style={{
                  maxHeight: "600px",
                  overflow: "auto",
                }}
              >
                {listRadios.map((item, key) => {
                  return (
                    <RadioDesign
                      name="groupOptionsTemplate"
                      type={"radio"}
                      id={`default-radio-${item.id}-${key}`}
                      key={`default-radio-${item.id}-${key}`}
                      label={item.name}
                      value={item.id}
                      onChange={(e) => {
                        onClickCheck(e, item.name, item);
                      }}
                      checked={selectedTemplate === String(item.id)}
                    />
                  );
                })}
              </div>
            </div>
          </Col>
          <Col xs="7">
            {selectedTemplate === null && (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={ImageInitiatives2}
                  style={{ height: "365px" }}
                  alt="description"
                />
              </div>
            )}
            {selectedTemplate !== null && (
              <div style={{ padding: "22px 38px" }}>
                <StepTitle>{t("preview")}</StepTitle>

                {selectedT.preview && (
                  <div style={{ height: "491.25px", overflow: "hidden" }}>
                    <img
                      alt="description"
                      src={selectedT.preview}
                      style={{
                        marginTop: "22px",
                        width: "100%",
                      }}
                    />
                  </div>
                )}
                {selectedT.preview === null && <div>{t("has_no_image")}</div>}
              </div>
            )}
          </Col>
        </RowItem>
      </CardNimbi>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              marginRight: "16px",
            }}
            onClick={() => {
              onChange("last");
            }}
            outlined
            icon={<NIcon iconname="arrowLeft" />}
            color="second"
          >
            {t("go_back")}
          </NButton>
          <NButton
            sizeStyle={ButtonSize.lg}
            style={{
              marginTop: "24px",
              borderRadius: "24px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              onChange("next");
            }}
            icon={<NIcon iconname="arrowRight" />}
            positionIcon="right"
            color="second"
          >
            {t("continue")}
          </NButton>
        </div>
      </div>
    </div>
  );
};

export default PageThird;

const TitleRadioButton = styled.div`
  background: #f8fbff;
  box-shadow: inset 0px -1px 0px #e0e0e0;
  padding: 7px 0px 7px 4px;
`;

const RadioDesign = styled(Form.Check)`
  display: block;
  padding: 15px 16px;
  position: relative;
  font-size: var(--font-size-md);
  input {
    margin-left: 0px;
    transform: scale(1.3);
    margin-top: 2px;
  }
  label {
    margin-left: 25px;
  }
  border-bottom: 1px solid #cdd4de;
`;
