import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useDefault } from "hooks";
import { Container, Row, Col, ModalProfile } from "components";
import * as Skeletons from "components/Skeletons";
import { uuidv4 } from "utils";
import { setActiveModule } from "store/actions/site.actions";
import { toggleSelected } from "store/actions/dashboard.actions";
import { ungroupTasks, restoreTasks } from "store/actions/crm.actions";
import {
  fetchPersonsProfile,
  fetchStudentTrackingsPerson,
  fetchPersonHistory,
  fetchStudentResponses,
  fetchCallResults,
} from "store/actions/async/crm-async.action";
import { fetchStudentNotes } from "store/actions/async/student-async.actions";
import { noTasks } from "../../assets/images";
import styles from "./Task.module.css";

import { APP_MODULES, getSessionFilters } from "utils";
import ResultHeader from "./ResultHeader";
import ResultItem from "./ResultItem";
import GroupedTasks from "./GroupedTasks";
import LastManagement from "./LastManagement";
import { markMoment } from "store/actions/crm.actions";
const DataList = () => {
  const dispatch = useDispatch();
  const selectedRow = useSelector((state) => state.crm.selectedRow, _.isEqual);
  const {
    tasks,
    moments,
    pending: { list: isPending },
    isMomentPending,
    isGroupedVisible,
    groupedTasks,
  } = useSelector((state) => state.crm, _.isEqual);

  const [showLastContact, setShowLastContact] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [studentInfo, setStudentInfo] = useState(null);
  const [studentProfile, setStudentProfile] = useState({ fullName: null });
  const [groupedStyles, setGroupedStyles] = useState("");
  const taskModuleTitle = useDefault("taskModuleTitle", "Tareas");
  const queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
  const [selectedM, setSelectedM] = useState(null);

  useEffect(() => {
    if (moments && moments.length > 0) {
      let selectItem = moments.find((moment) => moment.selected) ?? null;
      if (queryParams && queryParams.moment && selectItem === null) {
        dispatch(markMoment(queryParams.moment));
      } else {
        setSelectedM(selectItem);
      }
    }
  }, [dispatch, moments, queryParams]);

  useEffect(() => {
    if (selectedM) {
      dispatch(
        fetchStudentResponses({ moment: selectedM.id, page_size: 20, page: 1 })
      );
      dispatch(
        fetchCallResults({ moment: selectedM.id, page_size: 20, page: 1 })
      );
    }
  }, [dispatch, selectedM]);

  useEffect(() => {
    dispatch(toggleSelected(false)); // unmark dashboard module
  }, [dispatch]);

  useEffect(() => {
    if (taskModuleTitle)
      dispatch(setActiveModule({ title: taskModuleTitle, subtitle: null }));
  }, [dispatch, taskModuleTitle]);

  useEffect(() => {
    if (isGroupedVisible) {
      setGroupedStyles(
        [styles.groupedBlock, styles.accordionContent].join(" ")
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setGroupedStyles(
        [styles.groupedBlock, styles.hideAccordionContent].join(" ")
      );
    }
  }, [isGroupedVisible]);

  useEffect(() => {
    if (selectedRow) {
      setStudentProfile({ fullName: selectedRow.student_tracking.full_name });
    } else {
      setStudentProfile({ fullName: null });
    }
  }, [selectedRow]);

  const handleClose = () => {
    setStudentInfo(null);
    setShowLastContact(false);
  };

  const handleCloseProfile = () => setShowProfile(false);

  const toggleProfileModal = (task) => {
    const { student_id: studentId } = task.student_tracking;
    dispatch(fetchPersonsProfile(studentId));
    dispatch(fetchStudentTrackingsPerson(studentId));
    dispatch(fetchPersonHistory(studentId));
    dispatch(fetchStudentNotes(studentId));
    setShowProfile(true);
  };

  const toggleContactModal = (task) => {
    setStudentInfo({ full_name: task.student_tracking.full_name });
    dispatch(fetchPersonHistory(task.student_tracking.student_id));
    setShowLastContact(true);
  };

  const clearGroupedTasks = () => {
    dispatch(ungroupTasks());
    dispatch(restoreTasks());
  };

  const renderRows = () => {
    if (tasks.length > 0) {
      return (
        <Container style={{ minWidth: 992 }}>
          {tasks.map((s) => (
            <ResultItem
              key={`${uuidv4(8)}_${s.id}`}
              row={s}
              swModal={toggleContactModal}
              swProfileModal={toggleProfileModal}
            />
          ))}
        </Container>
      );
    }

    if (!isPending && !isMomentPending) {
      return (
        <Container className={styles.noTasks}>
          <Row>
            <Col className="d-flex flex-column align-items-center mt-5">
              <h3 className={styles.fadeIn}>No hay tareas para mostrar</h3>
              <img
                style={{ maxHeight: 300 }}
                className={`img ${styles.fadeIn}`}
                src={noTasks}
                alt="empty"
              />
            </Col>
          </Row>
        </Container>
      );
    }
    return null;
  };

  if (isPending || isMomentPending) {
    return (
      <Container className={styles.skeleton}>
        <Row>
          <Col className="d-flex justify-content-center mt-5">
            <Skeletons.Datalist />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      {tasks.length ? <ResultHeader /> : null}

      {/* Tasks grouped by student */}
      <Container style={{ minWidth: 992 }} className={groupedStyles}>
        <div className={`d-flex justify-content-between ${styles.headerBlock}`}>
          <span style={{ whiteSpace: "pre" }} className={styles.studentName}>
            {studentProfile.fullName || null}
          </span>
          <FontAwesomeIcon
            onClick={() => clearGroupedTasks()}
            className={styles.closeGrouped}
            icon={faTimes}
          />
        </div>
        <GroupedTasks
          data={groupedTasks}
          swModal={toggleContactModal}
          modalProfile={toggleProfileModal}
          visible
        />
      </Container>

      {/* Tasks list */}
      {renderRows()}

      {/* Modal LastContact */}
      {showLastContact ? (
        <LastManagement
          student={studentInfo}
          isVisible={showLastContact}
          onClose={handleClose}
        />
      ) : null}

      {/* Student profile Modal */}
      {showProfile ? (
        <ModalProfile
          notifyClose={handleCloseProfile}
          isVisible={showProfile}
        />
      ) : null}
    </>
  );
};

export default DataList;
