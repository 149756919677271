import {
  getTasks,
  getInsights,
  getInsightsType,
  getTopCallers,
  getTrackingTypes
} from "services/dashboard.service";

import { APP_MODULES, getSessionFilters } from "utils";
import * as DashActions from "../dashboard.actions";
import { handleErrorStatus } from "../../../services/errors";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

const getTaskList = () => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.DASHBOARD.FILTER_NAME);
    dispatch(DashActions.getTaskList(queryParams));
    getTasks(queryParams)
      .then(({ data }) => dispatch(DashActions.setList(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(DashActions.registerError({ err, key: "list" }));
      });
  };
};

const getStats = () => {
  return (dispatch) => {
    dispatch(DashActions.getStats());
    getInsights()
      .then(({ data }) => dispatch(DashActions.setStats(data)))
      .catch(handleErrorResponse);
  };
};

const getInsightsTeam = () => {
  return (dispatch) => {
    dispatch(DashActions.togglePending({ attr: "insightsTeam", sw: true }));
    getInsightsType("insights-team")
      .then(({ data }) => dispatch(DashActions.setInsightsTeam(data)))
      .catch(handleErrorResponse);
  };
};

const getInsightsOwn = () => {
  return (dispatch) => {
    dispatch(DashActions.togglePending({ attr: "insightsOwn", sw: true }));
    getInsightsType("insights-own")
      .then(({ data }) => dispatch(DashActions.setInsightsOwn(data)))
      .catch(handleErrorResponse);
  };
};

const getInsightsRisks = () => {
  return (dispatch) => {
    dispatch(DashActions.togglePending({ attr: "insightsRisks", sw: true }));
    getInsightsType("insights-risks")
      .then(({ data }) => dispatch(DashActions.setInsightsRisks(data)))
      .catch(handleErrorResponse);
  };
};

const getInsightsProgress = () => {
  return (dispatch) => {
    dispatch(DashActions.togglePending({ attr: "insightsProgress", sw: true }));
    getInsightsType("insights-progress")
      .then(({ data }) => dispatch(DashActions.setInsightsProgress(data)))
      .catch(handleErrorResponse);
  };
};

const getTopCallersList = (date = "today", attr = "topCallers") => {
  return (dispatch) => {
    dispatch(DashActions.togglePending({ attr: attr, sw: true }));
    getTopCallers({ date })
      .then(({ data }) => dispatch(DashActions.setTopCallers({ data, attr })))
      .catch(handleErrorResponse);
  };
};

const getInsightsTracking = () => {
  return (dispatch) => {
      dispatch(DashActions.togglePending({ attr: "insightsTracking", sw: true }));
      getTrackingTypes()
        .then(({ data }) => dispatch(DashActions.setInsightsTracking(data)))
        .catch(handleErrorResponse);
    };
}

export {
  getTaskList,
  getStats,
  getInsightsTeam,
  getInsightsTracking,
  getInsightsOwn,
  getInsightsRisks,
  getInsightsProgress,
  getTopCallersList,
};
