import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  CardNimbi,
  CommentBlock,
  DividerH,
  IconContainer,
} from "components/Nimbiv2/styled";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchExternalManagement } from "store/actions/async/external-management-async.action";
import {
  FormExternalManagement,
  ModalExternalManagement,
} from "containers/ExternalManagement";
import { createExternalManagement } from "services/externalManagement.service";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faEye,
  faFile,
} from "@fortawesome/pro-regular-svg-icons";
import styled from "styled-components";
import moment from "moment";
import IniciativeModal from "components/Nimbiv2/IniciativeModal";
import { BaseModal, OverlayTrigger, Tooltip } from "components";
import { ContainerEmpty, NSpinnerPoints } from "components/Nimbiv2";
import { getGlobalConfig } from "utils";
import EmptyListSVG from "assets/images/EmptyListSVG";

const ExternalManagementPage = ({ student_tracking_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    externalManagement,
    pending: { externalManagement: isPending },
  } = useSelector((state) => state.externalManagement);

  // const { user } = useSelector((state) => state.auth);

  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [objectCreate, setObjectCreate] = useState({});
  const [selectExternal, setSelectExternal] = useState(null);
  const [clean, setClean] = useState(false);

  useEffect(() => {
    if (student_tracking_id) {
      dispatch(
        fetchExternalManagement({ student_tracking: student_tracking_id })
      );
    }
  }, [dispatch, student_tracking_id]);

  const initialValues = {
    closed_at: null,
    channel: null,
    student: null,
    management_state: null,
    management_type: null,
    management_topic: null,
    comment: null,
  };

  const saveChanges = useCallback(
    async (values, { setSubmitting }) => {
      values["student_tracking"] = student_tracking_id;
      const { status, title, message, data } = await createExternalManagement(
        values
      );
      setSubmitting(false);
      if (status === 201) {
        setShow(true);
        setObjectCreate(data);
        dispatch(
          fetchExternalManagement({ student_tracking: student_tracking_id })
        );
        setClean(true);
        setTimeout(() => {
          setClean(false);
        }, 1000);
      } else if (status === 400) {
        toast.error(`${title} ${message}`);
        return false;
      } else {
        toast.error(t("error_create"));
      }
    },
    [student_tracking_id, dispatch, t]
  );

  return (
    <CardNimbi
      radius="8px"
      padding="23px 26px"
      style={{ height: "fit-content" }}
    >
      <div
        className="d-flex align-items-center"
        style={{ marginBottom: "18px" }}
      >
        <IconContainer className="mr-2">
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </IconContainer>
        <TitleText>{_.upperFirst(t("records"))}</TitleText>
      </div>{" "}
      <div style={{ marginBottom: "32px" }}>
        {isPending && <NSpinnerPoints />}
        {!isPending &&
          externalManagement &&
          externalManagement.map((item, key) => {
            return (
              <CommentBlock
                className="align-items-center "
                style={{ marginTop: "12px" }}
                key={`external-management-${key}`}
              >
                <div className="mr-auto">
                  <strong>{moment(item.closed_at).format("DD/MM/YYYY")}</strong>
                  {" - "}
                  {item.comment}
                </div>
                {getGlobalConfig("enable_massive_external_management", false) &&
                  item?.load_massive && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip>Gestión externa cargada desde archivo</Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        style={{ cursor: "pointer", marginRight: "5px" }}
                      />
                    </OverlayTrigger>
                  )}
                <FontAwesomeIcon
                  icon={faEye}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectExternal(item);
                    setShowDetail(true);
                  }}
                />
              </CommentBlock>
            );
          })}
        {!isPending &&
          externalManagement &&
          externalManagement.length === 0 && (
            <ContainerEmpty
              version="2"
              image={<EmptyListSVG />}
              text={"Aún no hay gestiones externas"}
              description={
                <div>Empieza creando una utilizando el formulario</div>
              }
            />
          )}
      </div>
      <DividerH />
      <div>
        <div
          className="d-flex align-items-center"
          style={{ marginTop: "22px", marginBottom: "30px" }}
        >
          <IconContainer className="mr-2">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </IconContainer>
          <TitleText>{t("new_registration")}</TitleText>
        </div>{" "}
        {!clean && (
          <FormExternalManagement
            formValues={initialValues}
            onFormSubmit={saveChanges}
          />
        )}
      </div>
      <IniciativeModal
        show={show}
        handleClose={() => {
          setShow(false);
        }}
        buttonAction={() => {
          setShow(false);
          setSelectExternal(objectCreate);
          setShowDetail(true);
        }}
        textTitle={t("your_external_management_created")}
        description={t("you_check_external_management")}
        textButton={t("see_management")}
        showButtonClose={true}
      />
      <BaseModal
        isVisible={showDetail}
        onClose={() => {
          setShowDetail(false);
        }}
        size="lg"
        title={t("management_detail")}
      >
        <ModalExternalManagement
          externalTask={selectExternal}
          isPending={false}
        />
      </BaseModal>
    </CardNimbi>
  );
};

export default ExternalManagementPage;

const TitleText = styled.div`
  color: var(--text-primary, #2a2b2e);
  font-size: 18px;
  font-weight: 700;
  line-height: 140%;
`;
