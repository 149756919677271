import React, { useState } from "react";
import { toast } from "react-toastify";

import { Container, Row, Col } from "components";
import { passwordRecovery } from "services/authService";
import FormRecoveryComponent from "./PasswordRecoveryForm";
import styles from "./Form.module.css";

const PasswordRecoveryForm = () => {
  const rowStyles = `justify-content-md-center align-items-center ${styles.loginBlock}`;

  const [isPending, setPending] = useState(false);

  const submitForm = async (user) => {
    setPending(true);
    const { status, message } = await passwordRecovery(user);

    if (status === 200) {
      toast.info(
        "Se enviarán los datos para recuperar contraseña al correo indicado"
      );
    } else {
      toast.error(message);
    }
    setPending(false);
  };

  return (
    <Container style={{ height: "85%" }}>
      <Row className={rowStyles}>
        <Col md={4}>
          <FormRecoveryComponent onSubmit={submitForm} isPending={isPending} />
        </Col>
      </Row>
    </Container>
  );
};

export default PasswordRecoveryForm;
