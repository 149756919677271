import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import logger from "./services/logService";
import initStore from "./store/store";
import { createRoot } from "react-dom/client";
import "./i18n";

if (process.env.REACT_APP_LOG_ROCKET) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET || "");
  setupLogRocketReact(LogRocket);
}

const store = initStore();
logger.init();

const domain = process.env.REACT_APP_API_URL || "";
const cspConfig = {
  directives: {
    defaultSrc: ["'none'"],
    scriptSrc: [
      "'self'",
      "https://cdn.logrocket.io https://cdn.lr-ingest.io https://cdn.lr-in.com https://cdn.lr-in-prod.com https://cdn.lr-ingest.com",
      "https://cdn.ingest-lr.com https://cdn.lr-intake.com https://cdn.intake-lr.com",
      "https://tableau.datalized.cl blob:",
    ],
    styleSrc: ["'self'", "'unsafe-hashes'", "'unsafe-inline'"],
    imgSrc: [
      "'self'",
      "data:",
      "https://nimbi-storage.s3.amazonaws.com",
      "https://nimbi-help-center.s3.us-east-2.amazonaws.com",
      "*.google.com",
      "*.google.mx",
      "*.google.cl",
      "*.google.bo",
      "*.google.pe",
      "*.google.com.ar",
      "*.google.com.mx",
      "*.google.com.cl",
      "*.google.com.bo",
      "*.google.com.pe",
      "blob:",
    ],
    connectSrc: [
      "'self'",
      "https://analytics.google.com/",
      "wss://*.pusher.com",
      "https://*.doubleclick.net/",
      "https://*.ingest.sentry.io",
      "https://login.microsoftonline.com/",
      "nimbi-storage.s3.amazonaws.com",
      "https://www.googleapis.com/",
      "https://*.logrocket.io https://*.lr-ingest.io https://*.logrocket.com https://*.lr-in.com https://*.lr-in-prod.com",
      "https://*.lr-ingest.com https://*.ingest-lr.com https://*.lr-intake.com https://*.intake-lr.com",
    ],
    frameSrc: [
      "datalized.cl",
      "*.datalized.cl",
      "https://tableau.datalized.cl/",
      "nimbi-demanda.s3.us-east-2.amazonaws.com",
      "https://accounts.google.com/",
    ],
    manifestSrc: ["'self'"],
    scriptSrcElem: [
      "'self'",
      "https://accounts.google.com/",
      "https://tagmanager.google.com/",
      "https://www.googletagmanager.com/",
      "'unsafe-inline'",
    ],
    styleSrcElem: [
      "self",
      "'unsafe-inline'",
      "https://fonts.googleapis.com",
      "https://accounts.google.com/",
    ],
    fontSrc: [
      "'self'",
      "https://fonts.gstatic.com",
      "https://maxcdn.bootstrapcdn.com",
    ],
    baseUri: ["'self'"],
    formAction: ["'self'", domain],
    workSrc: ["'self'", "blob:"],
  },
};
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <HelmetProvider>
          <Helmet>
            <meta
              http-equiv="Content-Security-Policy"
              content={[
                `default-src ${cspConfig.directives.defaultSrc.join(" ")}`,
                `script-src ${cspConfig.directives.scriptSrc.join(" ")}`,
                `style-src ${cspConfig.directives.styleSrc.join(" ")}`,
                `img-src ${cspConfig.directives.imgSrc.join(" ")}`,
                `connect-src ${cspConfig.directives.connectSrc.join(
                  " "
                )} ${domain}`,
                `manifest-src ${cspConfig.directives.manifestSrc.join(" ")}`,
                `frame-src ${cspConfig.directives.frameSrc.join(" ")}`,
                `style-src-elem ${cspConfig.directives.styleSrcElem.join(" ")}`,
                `script-src-elem ${cspConfig.directives.scriptSrcElem.join(
                  " "
                )}`,
                `font-src ${cspConfig.directives.fontSrc.join(" ")}`,
                `base-uri ${cspConfig.directives.baseUri.join(" ")}`,
                `form-action ${cspConfig.directives.formAction.join(" ")}`,
                `worker-src ${cspConfig.directives.workSrc.join(" ")}`,
              ].join("; ")}
            />
          </Helmet>
          <App />
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  </>
);

serviceWorker.unregister();
