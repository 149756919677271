export const GET_STUDENTS = "GET_STUDENTS";
export const SET_STUDENTS = "SET_STUDENTS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const UPDATE_STUDENT_INFO = "UPDATE_STUDENT_INFO";
export const SET_STUDENT_TAGS = "SET_STUDENT_TAGS";
export const TOGGLE_STAG_STATUS = "TOGGLE_STAG_STATUS";
export const TOGGLE_CHECKED = "TOGGLE_CHECKED";
export const TOGGLE_CHECKED_PAGE = "TOGGLE_CHECKED_PAGE";
export const TOGGLE_MARK_ITEMS = "TOGGLE_MARK_ITEMS";
export const TOGGLE_LIST_CHECKABLE = "TOGGLE_LIST_CHECKABLE";
export const SET_NOTES = "SET_NOTES";
export const TOGGLE_CHECKED_ARRAY = "TOGGLE_CHECKED_ARRAY";
export const SET_CUSTOM_PERSON = "SET_CUSTOM_PERSON";
export const SET_STUDENT_INSIGHTS = "SET_STUDENT_INSIGHTS";
export const SET_STUDENT_RISK = "SET_STUDENT_RISK";
export const GET_CUSTOM_PERSON = "GET_CUSTOM_PERSON";
export const SET_COURSE_SELECTED = "SET_COURSE_SELECTED";
export const CLEAN_CUSTOM_PERSON = "CLEAN_CUSTOM_PERSON";
export const GET_STUDENT_INSIGHTS = "GET_STUDENT_INSIGHTS";
export const GET_ACADEMIC_PROGRESS = "GET_ACADEMIC_PROGRESS";
export const SET_ACADEMIC_PROGRESS = "SET_ACADEMIC_PROGRESS";
export const SET_PROGRESS_ATTENDANCE = "SET_PROGRESS_ATTENDANCE";
export const SET_PROGRESS_GRADES = "SET_PROGRESS_GRADES";
export const GET_PROGRESS_GRADES = "GET_PROGRESS_GRADES";
export const GET_PROGRESS_ATTENDANCE = "GET_PROGRESS_ATTENDANCE";
export const GET_ENTERABLE_ACTION_PLANS = "GET_ENTERABLE_ACTION_PLANS";
export const SET_ENTERABLE_ACTION_PLANS = "SET_ENTERABLE_ACTION_PLANS";
export const GET_CHANGE_LOGS = "GET_CHANGE_LOGS";
export const SET_CHANGE_LOGS = "SET_CHANGE_LOGS";
export const GET_RISK_HISTORY = "GET_RIKS_HISTORY";
export const SET_RISK_HISTORY = "SET_RIKS_HISTORY";
// getting all students
const defaultQueryParams = {
  page: 1,
  page_size: Number(process.env.REACT_APP_DEFAULT_PAGESIZE),
  client_filters: null,
};

export const registerError = ({ err = null, key = "" }) => ({
  type: REGISTER_ERROR,
  payload: { err, key },
});
export const getStudents = (queryParams = defaultQueryParams) => ({
  type: GET_STUDENTS,
  payload: queryParams,
});

export const setStudents = (results) => ({
  type: SET_STUDENTS,
  payload: results,
});
export const updateStudentInfo = (student) => ({
  type: UPDATE_STUDENT_INFO,
  payload: student,
});

export const setStudentTags = (data) => ({
  type: SET_STUDENT_TAGS,
  payload: data,
});

export const toggleSTagStatus = ({ tagName, sw }) => ({
  type: TOGGLE_STAG_STATUS,
  payload: { tagName, sw },
});
export const toggleChecked = ({ idList, sw }) => ({
  type: TOGGLE_CHECKED,
  payload: { idList, sw },
});
export const toggleCheckedPage = (sw) => ({
  type: TOGGLE_CHECKED_PAGE,
  payload: sw,
});
export const toggleMarkItems = (key, value) => ({
  type: TOGGLE_MARK_ITEMS,
  payload: { key, value },
});
export const toggleCheckableList = (sw) => ({
  type: TOGGLE_LIST_CHECKABLE,
  payload: sw,
});

export const setStudentNotes = ({ studentID, notes }) => ({
  type: SET_NOTES,
  payload: { studentID, notes },
});

export const toggleCheckedArray = ({ listID }) => ({
  type: TOGGLE_CHECKED_ARRAY,
  payload: { listID },
});

export const getCustomPerson = () => ({
  type: GET_CUSTOM_PERSON,
  payload: null,
});

export const setCustomPerson = (data) => ({
  type: SET_CUSTOM_PERSON,
  payload: data,
});

export const cleanCustomPerson = () => ({
  type: CLEAN_CUSTOM_PERSON,
  payload: null,
});

export const getStudentInsightsCards = () => ({
  type: GET_STUDENT_INSIGHTS,
  payload: null,
});

export const setStudentInsights = ({ insights }) => ({
  type: SET_STUDENT_INSIGHTS,
  payload: { insights },
});

export const setStudentRisk = ({ risk }) => ({
  type: SET_STUDENT_RISK,
  payload: { risk },
});

export const setStudentCourseSelected = ({ courseSelected }) => ({
  type: SET_COURSE_SELECTED,
  payload: { courseSelected },
});

export const getAcademicProgress = (student_tracking_id) => ({
  type: GET_ACADEMIC_PROGRESS,
  payload: student_tracking_id,
});

export const setAcademicProgress = (data) => ({
  type: SET_ACADEMIC_PROGRESS,
  payload: data,
});

export const getProgressGrades = () => ({
  type: GET_PROGRESS_GRADES,
  payload: null,
});

export const setProgressGrades = (data) => ({
  type: SET_PROGRESS_GRADES,
  payload: data,
});

export const getProgressAttendance = () => ({
  type: GET_PROGRESS_ATTENDANCE,
  payload: null,
});

export const setProgressAttendance = (data) => ({
  type: SET_PROGRESS_ATTENDANCE,
  payload: data,
});

export const getEnterableActionPlans = () => ({
  type: GET_ENTERABLE_ACTION_PLANS,
  payload: null,
});

export const setEnterableActionPlans = (data) => ({
  type: SET_ENTERABLE_ACTION_PLANS,
  payload: data,
});

export const getChangeLogs = (queryParams = defaultQueryParams) => ({
  type: GET_CHANGE_LOGS,
  payload: queryParams,
});

export const setChangeLogs = (data) => ({
  type: SET_CHANGE_LOGS,
  payload: data,
});

export const getRiskHistory = (data) => ({
  type: GET_RISK_HISTORY,
  payload: data,
});

export const setRiskHistory = (data) => ({
  type: SET_RISK_HISTORY,
  payload: data,
});
