import React, { useCallback, useEffect, useState } from "react";
import { CardNimbi, DividerH, RowItem } from "components/Nimbiv2/styled";
import { Col, Spinner } from "react-bootstrap";
import NavigatedViewer from "bpmn-js/lib/NavigatedViewer";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";
import axios from "axios";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSquarePlus,
} from "@fortawesome/pro-regular-svg-icons";
import {
  ArrowLineMomentDetail,
  ArrowMomentDetail,
  FirstClockMoment,
  ImageGearMoment,
  ImagePaperMoment,
  PlusDetailMoment,
  SecondClockMoment,
  TaskDiagramImage,
  XDetailMoment,
} from "assets/images";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { NButton } from "components/Nimbiv2";
const PageDiagram = () => {
  const [diagram, diagramSet] = useState("");
  const [open, setOpen] = useState(true);
  const [modeler, setModeler] = useState(null);
  const { t } = useTranslation();
  const {
    moment,
    pending: { moment: isPending },
  } = useSelector((state) => state.moment);

  const renderDiagram = useCallback(async () => {
    if (diagram.length > 0) {
      const container = document.getElementById("diagram-bpmn");
      const modelerLocal = new NavigatedViewer({
        container,
        keyboard: {
          bindTo: document,
        },
      });
      await modelerLocal.importXML(diagram);
      modelerLocal.get("canvas").zoom("fit-viewport", "auto");
      setModeler(modelerLocal);
    }
  }, [diagram]);

  useEffect(() => {
    if (diagram.length === 0 && moment) {
      axios
        .get(moment.workflow_file)
        .then((r) => {
          diagramSet(r.data);
        })
        .catch((e) => {
          toast.error(t("problems_loading_diagram"));
        });
    }
    if (diagram.length > 0 && moment) {
      renderDiagram();
    }
  }, [diagram, moment, renderDiagram, t]);

  useEffect(() => {
    if (moment === null) {
      diagramSet("");
    }
  }, [moment]);

  const downloadDiagram = () => {
    modeler.saveSVG({ format: true }, function (error, svg) {
      if (error) {
        return;
      }
      var svgBlob = new Blob([svg], {
        type: "image/svg+xml",
      });
      const blobURL = URL.createObjectURL(svgBlob);
      const image = new Image();
      const canvas = document.createElement("canvas");
      canvas.style.visibility = "hidden";

      let png = "";
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");

        if (context) {
          context.fillStyle = "#fff";
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(image, 0, 0, image.width, image.height);
          png = canvas.toDataURL();
          const download = (href, name) => {
            const link = document.createElement("a");
            link.download = name;
            link.style.visibility = "hidden";
            document.body.append(link);
            link.href = href;
            link.click();
            link.remove();
          };
          download(png, Math.random(36).toString().substring(7) + ".jpg");
        }
      };
      image.src = blobURL;
    });
  };

  return (
    <RowItem>
      <Col lg="12" md="6" className="px-0 mb-1 mb-md-0">
        <CardNimbi padding="32px 24px">
          {!(isPending || moment === null) && diagram.length > 0 && (
            <NButton
              style={{
                position: "absolute",
                right: "20px",
                top: "20px",
                zIndex: "10",
              }}
              onClick={downloadDiagram}
            >
              {t("download_diagram")}
            </NButton>
          )}
          {!(isPending || moment === null) && (
            <DiagramContainer>
              <div
                id="diagram-bpmn"
                style={{
                  //border: "1px solid #000000",
                  height: "364px",
                  width: "100%",
                  margin: "auto",
                }}
              ></div>
            </DiagramContainer>
          )}
          {(isPending || moment === null) && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "364px" }}
            >
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          )}
        </CardNimbi>
      </Col>
      <div style={{ width: "100%" }}>
        <TopBorderContainer borderBottom={!open}>
          <div>{t("diagram_legend")}</div>
          <FontAwesomeIcon
            icon={!open ? faChevronDown : faChevronUp}
            color="#fff"
            onClick={() => {
              setOpen(!open);
            }}
          />
        </TopBorderContainer>
        {open && (
          <ContainerDetail>
            <RowItem style={{ paddingBottom: "12px" }}>
              <Col md="3" className="d-flex align-items-center">
                <img
                  src={TaskDiagramImage}
                  height="37.985px"
                  alt="task-diagram"
                />
                <div style={{ fontSize: "10px", marginLeft: "14px" }}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("task_diagram_description"),
                    }}
                  ></span>
                  {t("task_diagram_descripcion_1") !== "" && (
                    <>
                      <FontAwesomeIcon
                        icon={faSquarePlus}
                        height={"15px"}
                        style={{ marginLeft: "5px", marginRight: "5px" }}
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t("task_diagram_descripcion_1"),
                        }}
                      ></span>{" "}
                    </>
                  )}
                </div>
              </Col>
              <Col
                md="3"
                className="d-flex align-items-center justify-content-center"
                style={{
                  borderLeft: "1px rgba(221, 227, 236, 0.40) solid",
                }}
              >
                <div>
                  <div className="d-flex align-items-center">
                    <img
                      src={ImageGearMoment}
                      height="24px"
                      alt="gear-moment"
                    />
                    <div>{t("service_task")}</div>
                  </div>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginTop: "4px" }}
                  >
                    <img src={ImagePaperMoment} height="24px" alt="paper" />
                    <div>{t("script_task")}</div>
                  </div>
                </div>
              </Col>
              <Col
                md="3"
                className="d-flex align-items-center"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  borderLeft: "1px rgba(221, 227, 236, 0.40) solid",
                }}
              >
                <img
                  src={ArrowMomentDetail}
                  height="37.985px"
                  alt="arrow moment"
                />
                <div
                  style={{ fontSize: "10px", marginLeft: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: t("sequence_flow_description"),
                  }}
                ></div>
              </Col>
              <Col
                md="3"
                className="d-flex align-items-center"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  borderLeft: "1px rgba(221, 227, 236, 0.40) solid",
                }}
              >
                <img
                  src={ArrowLineMomentDetail}
                  height="37.985px"
                  alt="arrow line"
                />
                <div
                  style={{ fontSize: "10px", marginLeft: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: t("default_flow_description"),
                  }}
                ></div>
              </Col>
            </RowItem>
            <DividerH />
            <RowItem style={{ paddingTop: "12px" }}>
              <Col md="4" className="d-flex align-items-center">
                <img src={XDetailMoment} height="37.985px" alt="x detail" />
                <div
                  style={{ fontSize: "10px", marginLeft: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: t("exclusive_gateway_description"),
                  }}
                ></div>
              </Col>
              <Col md="4" className="d-flex align-items-center">
                <img
                  src={PlusDetailMoment}
                  height="37.985px"
                  alt="plus moment detail"
                />
                <div
                  style={{ fontSize: "10px", marginLeft: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: t("parallel_gateway_description"),
                  }}
                ></div>
              </Col>
              <Col md="4" className="d-flex align-items-center">
                <img
                  src={FirstClockMoment}
                  height="37.156px"
                  alt="first clock"
                />
                <img
                  src={SecondClockMoment}
                  height="37.156px"
                  alt="second clock"
                />
                <div
                  style={{ fontSize: "10px", marginLeft: "5px" }}
                  dangerouslySetInnerHTML={{
                    __html: t("timer_diagram_description"),
                  }}
                ></div>
              </Col>
            </RowItem>
          </ContainerDetail>
        )}
      </div>
    </RowItem>
  );
};
export default PageDiagram;

const DiagramContainer = styled.div`
  .bjs-powered-by {
    display: none;
  }
`;

const TopBorderContainer = styled.div`
  border-radius: 8px 8px 0px 0px;
  ${(props) =>
    props.borderBottom ? " border-radius: 8px 8px 8px 8px !important;" : ""}
  background: var(--gray-scale-1000, #9cabc2);
  padding: 8px 16px;
  color: var(--gray-scale-100, #fafcfe);
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

const ContainerDetail = styled.div`
  border-radius: 0px 0px 8px 8px;
  padding: 20px 16px;
  width: 100%;
  background: #fff;
`;
