import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styles from "./NProfileTabs.module.css";
import { useTranslation } from "react-i18next";

const StudentNotes = ({ studentID }) => {
  const { t } = useTranslation();
  const { list } = useSelector((state) => state.student);

  const renderNotes = () => {
    if (!studentID || list.length === 0) return null;
    const student = list.find((row) => row.id === studentID);
    if (!student) return null;
    const { notes: allNotes = [] } = student;

    if (allNotes.length === 0) {
      return (
        <div
          style={{
            background:
              "linear-gradient(0deg, rgba(166, 196, 255, 0.2), rgba(166, 196, 255, 0.2)), #FFFFFF",
            borderRadius: "8px",
            padding: "14px 17px",
            marginTop: "11px",
          }}
        >
          {t("no_observations")}
        </div>
      );
    }

    return allNotes.map((item) => {
      const fullName = `${item.user.first_name} ${item.user.last_name}`;
      const createdAt = moment(item.created_at).format("DD-MM-YYYY HH:mm:SS");
      return (
        <div key={item.id} className={`d-flex ${styles.noteBlock}`}>
          <div className={styles.noteEntry}>
            <p>{item.note}</p>
            <div className={`d-flex justify-content-end ${styles.noteInfo}`}>
              {`${fullName}.  `}
              <span>{createdAt}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  return renderNotes();
};

export default StudentNotes;
