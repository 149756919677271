import React from "react";
import _ from "lodash";
import moment from "moment";
import { Col } from "react-bootstrap";
import { NAuthor, NInfoDetail, NSpinnerPoints } from "components/Nimbiv2";
import { DividerH, RowItem } from "components/Nimbiv2/styled";
import { Channels } from "utils/constants";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getFormConfig, getGlobalConfig } from "utils";

const ModalExternalManagement = ({ externalTask, isPending }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      <div>
        {isPending && <NSpinnerPoints />}
        {!isPending && externalTask && (
          <RowItem>
            <Col md="12" style={{ paddingLeft: "0px" }}>
              <RowItem>
                <Col md="4">
                  <NInfoDetail
                    title={_.upperFirst(t("name"))}
                    detail={externalTask?.student_tracking?.full_name ?? "-"}
                    onClick={() => {
                      history.push(
                        `/alumnos/${externalTask?.student_tracking?.student_id}/`
                      );
                    }}
                  />
                </Col>
                <Col md="4">
                  <NInfoDetail
                    title={t("management_date")}
                    detail={moment(externalTask.closed_at).format("DD-MM-YYYY")}
                  />
                </Col>
                <Col md="4">
                  <NInfoDetail
                    title={_.upperFirst(t("type"))}
                    detail={externalTask.management_type}
                  />
                </Col>
                <Col md="4">
                  <NInfoDetail
                    marginTop="32px"
                    title={_.upperFirst(t("topic"))}
                    detail={externalTask.management_topic}
                  />
                </Col>
                {getFormConfig("form_external_management", "show_subtopic") && (
                  <Col md="4">
                    <NInfoDetail
                      marginTop="32px"
                      title={_.upperFirst(t("sub_topic"))}
                      detail={externalTask.management_subtopic}
                    />
                  </Col>
                )}
                <Col md="4">
                  <NInfoDetail
                    title={_.upperFirst(t("channel"))}
                    marginTop="32px"
                    detail={_.upperFirst(
                      _.get(
                        Channels,
                        externalTask.channel,
                        externalTask.channel
                      )
                    )}
                  />
                </Col>
                {getGlobalConfig(
                  "enable_massive_external_management",
                  false
                ) && (
                  <Col md="4">
                    <NInfoDetail
                      title={"Cargado masivo"}
                      marginTop="32px"
                      detail={externalTask?.load_massive ? "SI" : "NO"}
                    />
                  </Col>
                )}
                <Col md="4">
                  <NInfoDetail
                    title={_.upperFirst(t("state"))}
                    marginTop="32px"
                    detail={externalTask.management_state}
                  />
                </Col>
                <Col md="12">
                  <NInfoDetail
                    title={_.upperFirst(t("comment"))}
                    marginTop="32px"
                    detail={externalTask.comment}
                    wrap
                  />
                </Col>
              </RowItem>
              <DividerH style={{ marginTop: "32px", marginBottom: "20px" }} />
              <div
                style={{
                  marginTop: "19px",
                }}
              >
                <NAuthor
                  photo_url={externalTask?.created_by?.photo_url}
                  full_name={externalTask?.created_by?.full_name}
                  role={externalTask?.created_by?.role}
                />
              </div>
            </Col>
            {/* <Col md="4" style={{ paddingLeft: "0px" }}>
              <CardNimbi radius="8px" padding="32px 24px" height="auto">
                <strong style={{ fontSize: "20px" }}>
                  {_.upperFirst(t("student_information"))}
                </strong>
                <DividerH style={{ marginTop: "10px", marginBottom: "32px" }} />
                <CommentBlock padding="24px">
                  <div style={{ width: "100%" }}>
                    <RowItem style={{ fontSize: "12px" }}>
                      <Col md="6">
                        <div style={{ fontWeight: "500" }}>
                          {_.upperFirst(t("name"))}
                        </div>
                        {externalTask?.student_tracking?.full_name ?? "-"}
                      </Col>
                      <Col md="6">
                        <div style={{ fontWeight: "500" }}>
                          {t("document_type")}
                        </div>
                        {externalTask?.student_tracking?.document_type ?? "-"}
                      </Col>
                      <Col md="6">
                        <div style={{ fontWeight: "500", marginTop: "28px" }}>
                          DNI
                        </div>
                        {externalTask?.student_tracking?.document_number ?? "-"}
                      </Col>
                      <Col md="6">
                        <div style={{ fontWeight: "500", marginTop: "28px" }}>
                          {_.upperFirst(translation("campus"))}
                        </div>
                        {externalTask?.student_tracking?.campus ?? "-"}
                      </Col>
                      <Col md="6">
                        <div style={{ fontWeight: "500", marginTop: "28px" }}>
                          {externalTask?.student_tracking?.hasOwnProperty(
                            "carrera"
                          )
                            ? _.upperFirst(t("process"))
                            : _.upperFirst(t("career"))}
                        </div>
                        {externalTask?.student_tracking?.tracking_object}
                      </Col>
                      {externalTask?.student_tracking?.hasOwnProperty(
                        "carrera"
                      ) && (
                        <Col md="6">
                          <div style={{ fontWeight: "500", marginTop: "28px" }}>
                            {_.upperFirst(t("career"))}
                          </div>
                          {externalTask?.student_tracking?.carrera}
                        </Col>
                      )}
                      <Col md="6">
                        <div style={{ fontWeight: "500", marginTop: "28px" }}>
                          {t("date_of_birth")}
                        </div>
                        {externalTask?.student_tracking?.birth_date}
                      </Col>
                      <Col md="6">
                        <div style={{ fontWeight: "500", marginTop: "28px" }}>
                          {_.upperFirst(t("mail"))}
                        </div>
                        {externalTask?.student_tracking?.email}
                      </Col>

                      <Col md="6">
                        <div style={{ fontWeight: "500", marginTop: "28px" }}>
                          {_.upperFirst(t("phone"))}
                        </div>
                        {externalTask?.student_tracking?.phone}
                      </Col>
                    </RowItem>
                  </div>
                </CommentBlock>
                <DividerH style={{ marginTop: "32px", marginBottom: "32px" }} />
                <NButton
                  padding="10px 15px"
                  size="14px"
                  onClick={() => {
                    history.push(
                      `/alumnos/${externalTask?.student_tracking?.student_id}/`
                    );
                  }}
                >
                  {t("see_student_file")}
                </NButton>
              </CardNimbi>
            </Col> */}
          </RowItem>
        )}
      </div>
    </div>
  );
};

export default ModalExternalManagement;
