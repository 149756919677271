import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { faLightbulbOn, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardNimbi, DividerH, IconContainer } from "components/Nimbiv2/styled";
import { Form, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import styles from "components/NProfileTabs/NProfileTabs.module.css";
import { fetchStudentNotes } from "store/actions/async/student-async.actions";
import { createNote } from "services/student.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ContainerEmpty, NBTextArea, NButton } from "components/Nimbiv2";
import EmptyListSVG from "assets/images/EmptyListSVG";

const ObservationPage = ({ studentID, isModal = false }) => {
  const { t } = useTranslation();
  const { list } = useSelector((state) => state.student);
  const [isSaving, setSaving] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [notes, setAllNotes] = useState([]);
  const dispatch = useDispatch();
  const addNewNote = async () => {
    setSaving(true);
    const payload = {
      note: noteText,
      student: studentID,
    };
    const { status } = await createNote(payload);

    if (status === 201) {
      dispatch(fetchStudentNotes(studentID));
      setSaving(false);
      setNoteText("");
      toast.success(t("create_observation_success"));
    } else {
      toast.error(t("failed_add_observation"));
    }
  };
  useEffect(() => {
    if (!studentID || list.length === 0) {
      setAllNotes(null);
      return null;
    }
    const student = list.find((row) => row.id === studentID);
    if (!student) {
      setAllNotes(null);
      return null;
    }
    const { notes: allNotes = [] } = student;
    setAllNotes(allNotes);
  }, [list, studentID]);

  return (
    <CardNimbi style={{ height: "auto" }}>
      <div className="d-flex align-items-center">
        <IconContainer style={{ marginRight: "13px" }}>
          <FontAwesomeIcon icon={faLightbulbOn} style={{ fontSize: "18px" }} />
        </IconContainer>
        <strong style={{ fontSize: "var(--font-size-lg)" }}>
          {_.upperFirst(t("observations"))}
        </strong>
      </div>
      {notes && notes.length === 0 && (
        <ContainerEmpty
          version="2"
          image={<EmptyListSVG />}
          text={t("no_observations")}
        />
      )}
      <div style={isModal ? { maxHeight: "200px", overflow: "scroll" } : {}}>
        {notes &&
          notes.length > 0 &&
          notes.map((item) => {
            const fullName = `${item.user.first_name} ${item.user.last_name}`;
            const createdAt = moment(item.created_at).format(
              "DD-MM-YYYY HH:mm:SS"
            );
            return (
              <div
                key={item.id}
                className={`d-flex ${styles.noteBlock}`}
                style={{
                  paddingTop: "10px",
                  marginTop: "10px",
                  borderRadius: "8px",
                }}
              >
                <div className={styles.noteEntry} style={{ width: "100%" }}>
                  <p>{item.note}</p>
                  <div
                    className={`d-flex justify-content-end ${styles.noteInfo}`}
                  >
                    {`${fullName}.  `}
                    <span>{createdAt}</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <DividerH
        style={
          isModal
            ? { background: "white" }
            : { marginTop: "32px", marginBottom: "32px", background: "white" }
        }
      ></DividerH>
      <div
        className="d-flex align-items-center"
        style={isModal ? { background: "white", paddingTop: "32px" } : {}}
      >
        <IconContainer style={{ marginRight: "13px" }}>
          <FontAwesomeIcon icon={faPencil} style={{ fontSize: "18px" }} />
        </IconContainer>
        <strong style={{ fontSize: "var(--font-size-lg)" }}>
          {t("new_observation")}
        </strong>
      </div>
      <div style={{ marginTop: "11px" }}>
        <Form.Group controlId="student-note">
          <NBTextArea
            value={noteText}
            maxLength={1000}
            onChange={(event) => setNoteText(event.target.value)}
          />
        </Form.Group>
        <div className="d-flex justify-content-end">
          <NButton
            disabled={isSaving || String(noteText).trim().length === 0}
            outlined
            onClick={() => {
              setNoteText("");
            }}
          >
            {_.upperFirst(t("clean"))}
          </NButton>
          <NButton
            style={{ marginLeft: "16px" }}
            disabled={isSaving || String(noteText).trim().length === 0}
            onClick={addNewNote}
          >
            {isSaving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}{" "}
            {_.upperFirst(t("save"))}
          </NButton>
        </div>
      </div>
    </CardNimbi>
  );
};
export default ObservationPage;
