import {
  getStudents,
  getStudentTags,
  getStudentsByTag,
  getStudentsInCourses,
  getStudentNotes,
  getInfoCustomPerson,
  getStudentInsights,
  getStudentRisk,
  getStudentAcademicProgress,
  getEnterableActionPlans,
  getChangeLogs,
  getRiskHistory,
} from "services/student.service";
import {
  APP_MODULES,
  cleanNullJson,
  getDefaultFilters,
  getSessionFilters,
} from "utils";
import { handleErrorStatus } from "services/errors";
import * as StudentActions from "../student.actions";

const handleErrorResponse = (err) => {
  const status = err instanceof Map ? err.get("status") : 500;
  if (status > 0) return handleErrorStatus(status);
};

export const fetchStudents = (initialParams = null) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(
      APP_MODULES.STUDENT.FILTER_NAME,
      false,
      getDefaultFilters("students", true)
    );
    dispatch(StudentActions.getStudents(initialParams ?? queryParams));
    getStudents(initialParams ?? queryParams)
      .then(({ data }) => dispatch(StudentActions.setStudents(data)))
      .catch((err) => {
        handleErrorResponse(err);
        dispatch(StudentActions.registerError({ err, key: "list" }));
      });
  };
};

export const fetchStudentsInCourses = (listCourses) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(
      APP_MODULES.STUDENT.FILTER_NAME,
      true
    );
    dispatch(StudentActions.getStudents(queryParams));
    getStudentsInCourses(listCourses, queryParams)
      .then(({ data }) => dispatch(StudentActions.setStudents(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchStudentTags = () => {
  return (dispatch) => {
    getStudentTags()
      .then(({ data }) => dispatch(StudentActions.setStudentTags(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchStudentsByTag = (tagId) => {
  return (dispatch) => {
    const queryParams = getSessionFilters(APP_MODULES.STUDENT.FILTER_NAME);
    dispatch(StudentActions.getStudents(queryParams));
    getStudentsByTag(tagId, queryParams)
      .then(({ data }) => dispatch(StudentActions.setStudents(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchStudentNotes = (studentID, queryParams = {}) => {
  return (dispatch) => {
    getStudentNotes(studentID, queryParams)
      .then(({ data }) =>
        dispatch(
          StudentActions.setStudentNotes({
            studentID,
            notes: data.results,
          })
        )
      )
      .catch(handleErrorResponse);
  };
};

export const fetchInfoCustomPerson = (id, initialParams = null) => {
  return (dispatch) => {
    dispatch(StudentActions.getCustomPerson());
    getInfoCustomPerson(id, cleanNullJson(initialParams))
      .then(({ data }) => {
        dispatch(StudentActions.setCustomPerson(data));
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };
};

export const fetchStudentInsights = (studentID, queryParams = {}) => {
  return (dispatch) => {
    dispatch(StudentActions.getStudentInsightsCards());
    getStudentInsights(studentID, cleanNullJson(queryParams))
      .then(({ data }) =>
        dispatch(
          StudentActions.setStudentInsights({
            insights: data,
          })
        )
      )
      .catch(handleErrorResponse);
  };
};

export const fetchStudentRisk = (studentID, queryParams = {}) => {
  return (dispatch) => {
    getStudentRisk(studentID, cleanNullJson(queryParams))
      .then(({ data }) =>
        dispatch(
          StudentActions.setStudentRisk({
            risk: data,
          })
        )
      )
      .catch(handleErrorResponse);
  };
};

export const fetchAcademicProgress = (student_tracking_id) => {
  return (dispatch) => {
    dispatch(StudentActions.getAcademicProgress(student_tracking_id));
    getStudentAcademicProgress(student_tracking_id)
      .then(({ data }) => dispatch(StudentActions.setAcademicProgress(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchProgressGrades = (student_tracking_id, queryParams = {}) => {
  return (dispatch) => {
    dispatch(StudentActions.getProgressGrades(student_tracking_id));
    getStudentAcademicProgress(student_tracking_id, {
      model: "grades",
      ...queryParams,
    })
      .then(({ data }) => dispatch(StudentActions.setProgressGrades(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchProgressAttendance = (
  student_tracking_id,
  queryParams = {}
) => {
  return (dispatch) => {
    dispatch(StudentActions.getProgressAttendance(student_tracking_id));
    getStudentAcademicProgress(student_tracking_id, {
      model: "attendances",
      ...queryParams,
    })
      .then(({ data }) => dispatch(StudentActions.setProgressAttendance(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchEnterableActionPlans = (
  student_tracking_id,
  queryParams = {}
) => {
  return (dispatch) => {
    dispatch(StudentActions.getEnterableActionPlans(student_tracking_id));
    getEnterableActionPlans(student_tracking_id, queryParams)
      .then(({ data }) =>
        dispatch(StudentActions.setEnterableActionPlans(data))
      )
      .catch(handleErrorResponse);
  };
};

export const fetchChangelogs = (student_tracking_id, queryParams = {}) => {
  return (dispatch) => {
    dispatch(StudentActions.getChangeLogs(queryParams));
    getChangeLogs(student_tracking_id, queryParams)
      .then(({ data }) => dispatch(StudentActions.setChangeLogs(data)))
      .catch(handleErrorResponse);
  };
};

export const fetchStudentRiskHistory = (
  student_tracking_id,
  queryParams = {}
) => {
  return (dispatch) => {
    dispatch(StudentActions.getRiskHistory(queryParams));
    getRiskHistory(student_tracking_id, queryParams)
      .then(({ data }) => dispatch(StudentActions.setRiskHistory(data)))
      .catch(handleErrorResponse);
  };
};
