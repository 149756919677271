import api from "./api";
import { generateUrlWithParams } from "utils";

const fetchHelpItemTypes = () => {
  const path = "help-center/types/";
  return api.get(path);
};

const fetchHelpItems = (queryParams) => {
  const path = generateUrlWithParams("help-center/items/", queryParams);
  return api.get(path);
};

export {
  fetchHelpItemTypes,
  fetchHelpItems
};
