import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import _ from "lodash";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CardNimbi, DividerH, CommentBlock } from "components/Nimbiv2/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulbOn } from "@fortawesome/pro-regular-svg-icons";
import { TitleCard } from "./detail.styled";
import CallResults from "components/NCallingInfo/CallResults";
import CallResponses from "components/NCallingInfo/CallResponses";
import { updateTask } from "services/crmService";
import { toggleActionRow } from "store/actions/crm.actions";
import { deleteLocalStorage, getGlobalConfig, typesTaskEvent } from "utils";
import { PusherContext } from "store/pusher";
import { saveQuestionTask } from "services/taskService";
import { fetchProcessTaskRequest } from "store/actions/async/task-async.action";
import {
  NBTextArea,
  NSpinnerPoints,
  NFormQuestion,
  NButton,
} from "components/Nimbiv2";
import { fetchMoments } from "store/actions/async/crm-async.action";
import RenderComment from "./RenderComment";
import PreviewComponent from "./PreviewComponent";
import { ButtonSize } from "utils/constants";
import { useTranslationLocal } from "hooks";

const STEPS_DATA = {
  normal: ["call_result", "student_response"],
  with_question: ["call_result", "question", "student_response", "preview"],
  question_end: ["call_result", "student_response", "question", "preview"],
  student_not_response: ["call_result"],
};

const ResultCall = ({ task, isPending, resultCategory = "normal" }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { translation } = useTranslationLocal();

  const { lastNotification } = useContext(PusherContext);
  const user = useSelector((state) => state.auth.user);
  const { callResults, studentResponseList: callResponses } = useSelector(
    (state) => state.crm
  );
  const {
    questions,
    pending: { questions: isPendingQuestions },
  } = useSelector((state) => state.task);

  const dispatch = useDispatch();
  const [callResult, setCallResult] = useState(null);
  const [studentResponse, setStudentResponse] = useState(null);
  const [isResponsesDisabled, setResponsesDisabled] = useState(true);
  const [isOtherSubjectDisabled, setOtherSubjectDisabled] = useState(true);
  const [customSubject, setCustomSubject] = useState("");
  const [comments, setComments] = useState("");
  const [isPendingLocal, setPending] = useState(false);
  const [callResultValue, setCallResultValue] = useState(null);
  const [callResultValueType, setCallResultValueType] = useState(null);
  const [callResponseValue, setCallResponseValue] = useState(null);
  const [callResponseValueType, setCallResponseValueType] = useState(null);

  const [requiredValue, setRequiredValue] = useState(false);
  const [isInvalidOther, setIsInvalidOther] = useState(false);

  const [responseQuestion, setResponseQuestion] = useState(null);
  // const [endForm, setEndForm] = useState(null);
  const [errorForm, setErrorform] = useState(false);

  const [position, setPosition] = useState(0);
  const [positionQuestion, setPositionQuestion] = useState(0);

  const [callResultRequiered, setCallResultRequiered] = useState(false);
  const [observationRequired, setObservationRequired] = useState(false);
  const [secondObservationRequired, setSecondObservationRequired] = useState(
    false
  );
  const [studentResponseReq, setStudentResponseReq] = useState(false);
  const [renderQuestion, setRenderQuestion] = useState(true);

  const [steps, setSteps] = useState(STEPS_DATA["normal"]);
  const [questionSelected, setQuestionSelected] = useState(null);
  const [cleanForm, setCleanForm] = useState(false);
  const [triggerNext, setTriggerNext] = useState(0);

  const [derive, setDerive] = useState("");
  const [secondComments, setSecondComments] = useState("");

  const {
    moments,
    tabTask: tab,
    singleLoads: { moments: singleMomentsLoaded },
  } = useSelector((state) => state.crm, _.isEqual);

  const {
    academicProgress,
    // academicStudentId,
  } = useSelector((state) => state.student);

  const listAcademicProgress = useMemo(() => {
    return (
      academicProgress?.progress?.filter((item) => {
        return item.grades_extras["riesgo_rendimiento"];
      }) ?? []
    );
  }, [academicProgress]);

  useEffect(() => {
    if ((moments || moments.length === 0) && !singleMomentsLoaded) {
      dispatch(fetchMoments());
    }
  }, [moments, dispatch, singleMomentsLoaded]);

  useEffect(() => {
    if (questions && questions.length > 0) {
      setQuestionSelected(questions[0]);
    }
  }, [questions]);

  const updateValueForm = useCallback((value) => {
    setResponseQuestion(value);
    setErrorform(false);
    setCleanForm(false);
  }, []);

  const toggleTasks = useCallback(
    (item) => {
      //await processTaskRequest(item, typesTaskEvent.RELEASED);
      dispatch(fetchProcessTaskRequest(item, typesTaskEvent.RELEASED));
      // lock/unlock others tasks
      dispatch(
        toggleActionRow({ taskId: item.id, lastTaskEvent: lastNotification })
      );
    },
    [dispatch, lastNotification]
  );

  const handleCommentChange = (event) => {
    const studentComments = event.target.value;
    const shrinkedComment = String(studentComments).substring(0, 1000);
    setComments(shrinkedComment);
    setObservationRequired(false);
  };

  const handleSecondCommentChange = (event) => {
    const studentSecondComments = event.target.value;
    const shrinkedComment = String(studentSecondComments).substring(0, 1000);
    setSecondComments(shrinkedComment);
    setSecondObservationRequired(false);
  };

  const handleResultChange = (result) => {
    setTriggerNext(0);
    setCallResult(result);
    setCallResponseValue(null);
    setCallResponseValueType(null);
    setRequiredValue(false);
    setCallResultRequiered(false);
    setResponseQuestion(null);
    setCleanForm(true);
    setRenderQuestion(false);
    setCallResultValueType(result.input_type);

    if (!result.enable_reactions && resultCategory === "normal") {
      setStudentResponse("");
      setResponsesDisabled(true);
      setSteps(STEPS_DATA["student_not_response"]);
    } else {
      let countStep = 0;
      setResponsesDisabled(false);
      if (questions.length === 1) {
        if (!questions[0]["dependence"]) {
          setSteps(STEPS_DATA["with_question"]);
          countStep = 1;
        }
      }
      if (questions.length > 0 && countStep === 0) {
        // Validate contact reason
        const questionReasonFilters = questions.filter(
          (items) =>
            items?.contact_reason !== null && items?.contact_reason?.length > 0
        );

        if (questionReasonFilters?.length > 0) {
          const question = questionReasonFilters.find((questionItem) =>
            questionItem?.contact_reason?.includes(task?.contact_reason_id)
          );
          if (question) {
            setSteps(STEPS_DATA["with_question"]);
            setQuestionSelected(question);
            countStep = 1;
          }
        } else {
          // validate dependences
          const questionFilters = questions.filter(
            (items) => items?.dependence !== null
          );

          if (questionFilters.length > 0) {
            setSteps(STEPS_DATA["question_end"]);
          } else {
            setSteps(STEPS_DATA["normal"]);
          }
        }
      }
      if (questions.length === 0) {
        setSteps(STEPS_DATA["normal"]);
      }
    }
  };

  const handleResponseChange = (response) => {
    setStudentResponse(response);
    setStudentResponseReq(false);
    setCallResponseValue(null);
    setRequiredValue(false);
    setIsInvalidOther(false);
    setOtherSubjectDisabled(response.code.toLocaleLowerCase() !== "resp_other");
    setCallResponseValueType(response.input_type);
    setCallResultValueType(null);
    if (questions.length > 0) {
      const questionFilters = questions.filter(
        (items) => items?.dependence !== null
      );
      if (questionFilters.length > 0) {
        setCleanForm(true);
        setResponseQuestion(null);
        const question = questionFilters.find((questionItem) =>
          questionItem?.dependence?.split(",")?.includes(response.code)
        );
        if (question) {
          setQuestionSelected(question);
          setSteps(STEPS_DATA["question_end"]);
        } else {
          const questionNull = questions.filter(
            (item) => item.dependence === null
          );
          if (questionFilters.length > 0 && questionNull.length > 0) {
            setQuestionSelected(questionNull[0]);
            setSteps(STEPS_DATA["question_end"]);
          } else {
            if (questions.length === 1) {
              if (!questions[0]["dependence"]) {
                setQuestionSelected(questions[0]);
                setSteps(STEPS_DATA["with_question"]);
              }
            } else {
              setQuestionSelected(null);
              setSteps(STEPS_DATA["normal"]);
            }
          }
        }
      }
    }
  };

  const handleSubjectChange = (event) => {
    setCustomSubject(event.target.value);
    setIsInvalidOther(false);
  };

  const saveQuestion = useCallback(async () => {
    let new_data = {};
    for (const value of Object.keys(responseQuestion)) {
      const new_item = {
        answer_id: null,
        answer_text: null,
        question_id: null,
        question_text: null,
        code: null,
        question_comment: null,
        input_type: null,
      };
      const merge_data = { ...new_item, ...responseQuestion[value] };
      new_data[value] = merge_data;
    }
    const formData = new FormData();
    formData.append("task_id", task.id);
    formData.append("answers", JSON.stringify(new_data));
    await saveQuestionTask(formData);
  }, [responseQuestion, task]);

  const validations = useCallback(() => {
    if (!callResult || (!isResponsesDisabled && !studentResponse)) {
      return false;
    }
    if (
      callResult.input_type != null &&
      (callResultValue === null || callResultValue === "")
    ) {
      setCallResultRequiered(true);
      return false;
    }

    if (
      (!isResponsesDisabled || steps.length === 1) &&
      getGlobalConfig("additional_remarks_required", false) &&
      (comments === null || comments === "")
    ) {
      setObservationRequired(true);
      return false;
    }
    if (
      (!isResponsesDisabled || steps.length === 1) &&
      getGlobalConfig("second_comment_required", false) &&
      (secondComments === null || secondComments === "")
    ) {
      setSecondObservationRequired(true);
      return false;
    }
    return true;
  }, [
    callResult,
    callResultValue,
    comments,
    studentResponse,
    isResponsesDisabled,
    secondComments,
    steps,
  ]);

  const validateResponseQuestion = useCallback(
    (position, response, question) => {
      const answer_text = response?.answer_text;
      const input_type = response?.input_type;
      if (answer_text || response?.question_comment) {
        if (answer_text && input_type === "only_comment") {
          setPositionQuestion(position);
        }
        if (input_type === "radio-button") {
          setPositionQuestion(position);
        } else if (
          input_type === "checkbox" &&
          (answer_text?.length > 0 || response?.question_comment)
        ) {
          setPositionQuestion(position);
        } else if (
          input_type === "academic_progress" &&
          ((answer_text &&
            Object.keys(answer_text).length === listAcademicProgress.length) ||
            (listAcademicProgress.length === 0 && response?.question_comment))
        ) {
          setPositionQuestion(position);
        } else if (
          input_type === "academic_assistance" &&
          (answer_text || response?.question_comment)
        ) {
          setPositionQuestion(position);
        } else {
          setErrorform(true);
        }
      } else {
        if (question?.is_required) {
          setErrorform(true);
        } else {
          setPositionQuestion(position);
        }
      }
    },
    [listAcademicProgress]
  );

  const nextValidationForm = useCallback(
    (endFormNew, response, question) => {
      if (!endFormNew) {
        const positionX = positionQuestion + 1;
        validateResponseQuestion(
          positionX,
          response[positionQuestion],
          question
        );
        return null;
      } else {
        setPosition((position) => position + 1);
        setRenderQuestion(true);
      }
    },
    [positionQuestion, validateResponseQuestion]
  );

  const saveTask = useCallback(async () => {
    if (!validations()) {
      return false;
    }

    setPending(true);
    let payload = {
      call_comment: isOtherSubjectDisabled ? null : customSubject,
      call_response: callResult.id,
      comments,
    };
    if (getGlobalConfig("enable_second_comment", false)) {
      payload.second_comments = secondComments;
    }

    if (!isResponsesDisabled) {
      payload.student_response = studentResponse.id;
    }
    if (callResponseValue && callResponseValueType) {
      if (callResponseValueType === "date") {
        payload.call_comment = moment(callResponseValue).format("YYYY-MM-DD");
      } else if (callResponseValueType === "datetime") {
        payload.call_comment = moment(callResponseValue).format(
          "YYYY-MM-DD HH:mm"
        );
      } else {
        payload.call_comment = callResponseValue;
      }
    } else if (callResultValue && callResultValueType) {
      if (callResultValueType === "date") {
        payload.call_comment = moment(callResultValue).format("YYYY-MM-DD");
      }
      if (callResultValueType === "datetime") {
        payload.call_comment = moment(callResultValue).format(
          "YYYY-MM-DD HH:mm"
        );
      } else {
        payload.call_comment = callResultValue;
      }
    }
    if (derive && derive !== "") {
      payload.derived_action_plan = derive;
    }

    if (questions.length > 1 && responseQuestion) {
      payload.code_question =
        responseQuestion[
          (Object.keys(responseQuestion).length - 1).toString()
        ].code;
      // Append answers (if task has callform with multiple answers) into task comments
      let multiple_answers = Object.keys(responseQuestion)
        .map((item) => {
          if (responseQuestion[item]["input_type"] !== "checkbox") return null;
          return responseQuestion[item]["answer_text"]
            .map((answer) => answer["text"])
            .join(", ");
        })
        .filter((item) => item !== null)
        .join(", ");
      if (multiple_answers) {
        if (payload.comments) {
          payload.comments =
            payload.comments +
            ". " +
            studentResponse.response_text +
            ": " +
            multiple_answers;
        } else {
          payload.comments =
            studentResponse.response_text + ": " + multiple_answers;
        }
      }
    }

    toggleTasks(task);
    deleteLocalStorage("taskSelect");
    const { status } = await updateTask(task.id, payload, task.external);
    if (
      !isResponsesDisabled &&
      !isPendingQuestions &&
      questions.length > 0 &&
      responseQuestion !== null &&
      status === 200
    ) {
      await saveQuestion();
    }
    // dispatch(getTaskList());

    if (status === 200) {
      toast.success(t("success_save_task"));
      history.goBack();
    } else {
      toast.error(t("error_save_task"));
      setPending(false);
      setResponsesDisabled(true);
    }
  }, [
    callResult,
    comments,
    secondComments,
    customSubject,
    isOtherSubjectDisabled,
    isResponsesDisabled,
    studentResponse,
    task,
    callResponseValue,
    callResultValue,
    history,
    isPendingQuestions,
    questions.length,
    saveQuestion,
    toggleTasks,
    callResponseValueType,
    callResultValueType,
    responseQuestion,
    t,
    derive,
    validations,
  ]);

  if (isPending) {
    return (
      <div style={{ marginTop: "10px" }}>
        <NSpinnerPoints />
      </div>
    );
  }

  const onChangeValue = (value, type) => {
    setCallResponseValue(value);
    setRequiredValue(false);
  };

  const onChangeCallValue = (value, type) => {
    setCallResultValue(value);
    setRequiredValue(false);
  };

  const nextValidateForm = () => {
    if (!callResult && steps[position] === "call_result") {
      setCallResultRequiered(true);
      return false;
    }
    if (!studentResponse && steps[position] === "student_response") {
      setStudentResponseReq(true);
      return false;
    }
    if (
      !isOtherSubjectDisabled &&
      (customSubject === null || customSubject === "") &&
      steps[position] === "student_response"
    ) {
      setIsInvalidOther(true);
      return false;
    }
    if (
      getGlobalConfig("additional_remarks_required", false) &&
      (steps[position] === "student_response" || steps.length === 1) &&
      (comments === null || comments === "")
    ) {
      setObservationRequired(true);
      return false;
    }
    if (
      getGlobalConfig("second_comment_required", false) &&
      (steps[position] === "student_response" || steps.length === 1) &&
      (secondComments === null || secondComments === "")
    ) {
      setSecondObservationRequired(true);
      return false;
    }
    if (
      studentResponse &&
      studentResponse.input_type !== null &&
      (callResponseValue === null || callResponseValue === "") &&
      steps[position] === "student_response"
    ) {
      setRequiredValue(true);
      return false;
    }
    if (
      callResult &&
      callResult.input_type !== null &&
      (callResultValue === null || callResultValue === "") &&
      steps[position] === "call_result"
    ) {
      setRequiredValue(true);
      return false;
    }
    return true;
  };

  return (
    <>
      {(task.assigned_to === null ||
        (task.assigned_to &&
          user &&
          task.assigned_to.username !== user.username)) && (
        <CardNimbi
          radius="8px"
          padding="23px 26px"
          style={{ height: "fit-content" }}
        >
          <CommentBlock>
            <div>
              <FontAwesomeIcon
                icon={faLightbulbOn}
                style={{
                  marginRight: "8px",
                  fontSize: "18px",
                  color: "#787C83",
                }}
              ></FontAwesomeIcon>
            </div>
            <div>
              <strong>{_.upperFirst(t("information"))}:</strong>{" "}
              {t("unselected_task")}.
            </div>
          </CommentBlock>
        </CardNimbi>
      )}

      {user && task.assigned_to && task.assigned_to.username === user.username && (
        <>
          <CardNimbi
            radius="8px"
            padding="23px 26px"
            style={{ height: "fit-content" }}
          >
            <CommentBlock>
              <div>
                <FontAwesomeIcon
                  icon={faLightbulbOn}
                  style={{
                    marginRight: "8px",
                    fontSize: "18px",
                    color: "#787C83",
                  }}
                ></FontAwesomeIcon>
              </div>
              <div>
                <strong>{_.upperFirst(t("objetive"))}:</strong>{" "}
                {task.contact_reason}
              </div>
            </CommentBlock>
            <div className="mt-2">
              <RenderComment task={task} />
            </div>
            <div style={{ minHeight: "200px" }}>
              {steps[position] === "call_result" && (
                <div>
                  <div style={{ marginTop: "39px" }}>
                    <TitleCard>
                      {translation(
                        "result_call_title",
                        "",
                        t("result_call"),
                        task?.script_template?.extras
                      )}
                    </TitleCard>
                  </div>
                  <DividerH style={{ marginTop: "15px" }}></DividerH>
                  <div style={{ marginTop: "18px" }}>
                    <CallResults
                      rows={callResults}
                      onChange={handleResultChange}
                      category={resultCategory}
                      selected={callResult}
                      parentId={task.id}
                      onChangeValue={(item) => {
                        setCallResultRequiered(false);
                        onChangeCallValue(item);
                      }}
                      callResultValue={callResultValue}
                      task={task}
                    />
                    {callResultRequiered && (
                      <p style={{ color: "red" }}>Selecciona una opción!.</p>
                    )}
                  </div>
                </div>
              )}
              {!isPendingQuestions &&
                questions.length > 0 &&
                renderQuestion &&
                questionSelected && (
                  <div
                    style={
                      steps[position] === "question"
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div style={{ marginTop: "39px" }}>
                      <TitleCard>
                        {questionSelected?.description
                          ? questionSelected.description
                          : translation(
                              "replace_question",
                              "",
                              t("questions"),
                              task?.script_template?.extras
                            )}
                      </TitleCard>
                    </div>
                    <DividerH style={{ marginTop: "15px" }}></DividerH>

                    <div style={{ marginTop: "18px" }}>
                      <NFormQuestion
                        data={questionSelected}
                        onChange={updateValueForm}
                        isDisabled={isResponsesDisabled}
                        position={positionQuestion}
                        clean={cleanForm}
                        triggerNext={triggerNext}
                        nextValidation={nextValidationForm}
                      />
                      {errorForm && (
                        <p style={{ color: "red" }}>
                          Falta responder la pregunta.
                        </p>
                      )}
                    </div>
                  </div>
                )}
              {steps[position] === "student_response" && (
                <div>
                  <div style={{ marginTop: "39px" }}>
                    <TitleCard>
                      {_.upperFirst(
                        translation(
                          "student_reaction",
                          "startCase",
                          t("student_reaction", {
                            student: translation("student", "", t("student")),
                          }),
                          task?.script_template?.extras
                        )
                      )}
                    </TitleCard>
                  </div>
                  <DividerH style={{ marginTop: "15px" }}></DividerH>

                  <div style={{ marginTop: "18px" }}>
                    <CallResponses
                      rows={callResponses}
                      onChange={handleResponseChange}
                      selected={studentResponse}
                      parentId={task.id}
                      hasDisabledOptions={isResponsesDisabled}
                      onChangeValue={onChangeValue}
                      requiredValue={requiredValue}
                      callResponseValue={callResponseValue}
                      callResult={callResult}
                    />
                    {callResponses &&
                      callResponses
                        .map((item) => item.code)
                        .includes("resp_other") &&
                      (callResult?.student_response_codes &&
                      callResult?.student_response_codes !== ""
                        ? callResult?.student_response_codes
                            .replace(" ", "")
                            .split(",")
                            .includes("resp_other")
                        : true) && (
                        <div>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              maxLength={60}
                              placeholder={t("other_reaction")}
                              value={customSubject}
                              onChange={handleSubjectChange}
                              disabled={isOtherSubjectDisabled}
                              isInvalid={isInvalidOther}
                            />
                            {isInvalidOther && (
                              <p style={{ color: "red" }}>
                                El campo es requerido!.
                              </p>
                            )}
                          </Form.Group>
                        </div>
                      )}
                    {studentResponseReq && (
                      <p style={{ color: "red" }}>Selecciona una opción!.</p>
                    )}
                  </div>
                </div>
              )}

              {steps[position] === "student_response" &&
                tab === "initiatives" &&
                task.enter_action_plans &&
                task.initiative_moments &&
                task.initiative_moments.length > 0 &&
                !task.external && (
                  <div style={{ marginBottom: "1rem" }}>
                    <div style={{ marginBottom: "0.5rem", fontWeight: "500" }}>
                      Derivar a:{" "}
                    </div>
                    <Form.Control
                      value={derive}
                      as="select"
                      onChange={(event) => {
                        let value = event.target.value;
                        setDerive(value);
                      }}
                    >
                      <option value="">---Seleccionar---</option>
                      {task &&
                        task.initiative_moments.map((item, key) => (
                          <option key={`moment-derive-${key}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </Form.Control>
                  </div>
                )}

              {((isResponsesDisabled && callResult) ||
                steps[position] === "student_response") && (
                <div className="mb-2">
                  <div style={{ marginTop: "39px" }}>
                    <TitleCard>
                      {translation(
                        "additional_remarks",
                        "",
                        t("additional_remarks"),
                        task?.script_template?.extras
                      )}
                    </TitleCard>
                  </div>
                  <DividerH style={{ marginTop: "15px" }}></DividerH>
                  <div style={{ marginTop: "18px" }}>
                    <NBTextArea
                      value={comments}
                      maxLength={1000}
                      onChange={handleCommentChange}
                      errorText={
                        observationRequired ? t("required_field") : null
                      }
                    />
                  </div>
                </div>
              )}

              {getGlobalConfig("enable_second_comment", false) &&
                ((isResponsesDisabled && callResult) ||
                  steps[position] === "student_response") && (
                  <div className="mb-2">
                    <div>
                      <TitleCard>
                        {translation(
                          "secondary_comment",
                          "",
                          t("secondary_comment"),
                          task?.script_template?.extras
                        )}
                      </TitleCard>
                    </div>
                    <DividerH style={{ marginTop: "15px" }}></DividerH>
                    <div style={{ marginTop: "18px" }}>
                      <NBTextArea
                        value={secondComments}
                        maxLength={1000}
                        onChange={handleSecondCommentChange}
                        errorText={
                          secondObservationRequired ? t("required_field") : null
                        }
                      />
                    </div>
                  </div>
                )}

              {steps[position] === "preview" && (
                <div style={{ marginTop: "39px" }}>
                  <TitleCard style={{ fontSize: "26px" }}>Resumen</TitleCard>
                  <div className="mt-4">
                    <TitleCard>
                      {translation(
                        "result_call_title",
                        "",
                        t("result_call"),
                        task?.script_template?.extras
                      )}
                    </TitleCard>
                    <div className="mt-1">{callResult.description}</div>
                  </div>
                  {!isPendingQuestions &&
                    questions.length > 0 &&
                    responseQuestion &&
                    Object.keys(responseQuestion).length > 0 && (
                      <PreviewComponent
                        title={questionSelected?.description}
                        responseQuestion={responseQuestion}
                      />
                    )}
                  {!isResponsesDisabled && (
                    <div className="mt-2">
                      <TitleCard>
                        {_.upperFirst(
                          translation(
                            "student_reaction",
                            "startCase",
                            t("student_reaction", {
                              student: translation("student", "", t("student")),
                            }),
                            task?.script_template?.extras
                          )
                        )}
                      </TitleCard>
                      <div className="mt-1">
                        {studentResponse.response_text}
                        {studentResponse.code === "resp_other" &&
                          `: ${customSubject}`}
                        {studentResponse.input_type === "date" &&
                          `: ${moment(callResponseValue).format("YYYY-MM-DD")}`}
                        {["text", "select"].includes(
                          studentResponse.input_type
                        ) && `: ${callResponseValue}`}
                      </div>
                    </div>
                  )}
                  <div className="mt-2">
                    <TitleCard>
                      {translation(
                        "additional_remarks",
                        "",
                        t("additional_remarks"),
                        task?.script_template?.extras
                      )}
                    </TitleCard>
                    <div className="mt-1">
                      {comments !== "" ? comments : "..."}
                    </div>
                  </div>
                  {getGlobalConfig("enable_second_comment", false) && (
                    <div className="mt-2">
                      <TitleCard>
                        {translation(
                          "secondary_comment",
                          "",
                          t("secondary_comment"),
                          task?.script_template?.extras
                        )}
                      </TitleCard>
                      <div className="mt-1">
                        {secondComments !== "" ? secondComments : "..."}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CardNimbi>
          <div
            className="d-flex justify-content-between w-100"
            style={{ marginTop: "28px" }}
          >
            {position < steps.length - 1 &&
              (!isResponsesDisabled || !callResult) && (
                <NButton
                  outlined
                  onClick={() => {
                    toggleTasks(task);
                    history.goBack();
                  }}
                  disabled={isPendingLocal}
                  style={{ marginRight: "16px", fontWeight: "500" }}
                  color="danger"
                  sizeStyle={ButtonSize.md}
                  border="24px"
                >
                  {_.upperFirst(t("cancel"))}
                </NButton>
              )}

            {position < steps.length - 1 &&
              (!isResponsesDisabled || !callResult) && (
                <div>
                  {position > 0 && (
                    <NButton
                      outlined
                      disabled={isPendingLocal}
                      onClick={() => {
                        if (
                          questions.length > 0 &&
                          steps[position] === "question" &&
                          positionQuestion > 0
                        ) {
                          const positionX = positionQuestion - 1;
                          setPositionQuestion(positionX);
                          return null;
                        }

                        setPosition(position - 1);
                      }}
                      style={{ marginRight: "16px", fontWeight: "500" }}
                      color="second"
                      sizeStyle={ButtonSize.md}
                      border="24px"
                    >
                      {_.upperFirst(t("previous"))}
                    </NButton>
                  )}
                  <NButton
                    onClick={() => {
                      if (steps[position] === "question") {
                        setTriggerNext((triggerNext) => triggerNext + 1);
                        return null;
                      } else {
                        if (nextValidateForm()) {
                          setPosition(position + 1);
                          setRenderQuestion(true);
                        }
                      }
                    }}
                    disabled={isPendingQuestions || callResults.length === 0}
                    color="second"
                    sizeStyle={ButtonSize.md}
                    style={{ fontWeight: "500" }}
                    border="24px"
                  >
                    {isPendingQuestions || callResults.length === 0 ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="mr-1"
                      />
                    ) : null}
                    {_.upperFirst(t("next"))}
                  </NButton>
                </div>
              )}
            {(position === steps.length - 1 ||
              (isResponsesDisabled && callResult)) && (
              <div style={{ display: "flex", width: "100%" }}>
                <div className="mr-auto">
                  <NButton
                    outlined
                    onClick={() => {
                      toggleTasks(task);
                      history.goBack();
                    }}
                    disabled={isPendingLocal}
                    color="danger"
                    style={{ fontWeight: "500" }}
                    border="24px"
                    sizeStyle={ButtonSize.md}
                  >
                    {_.upperFirst(t("cancel"))}
                  </NButton>
                </div>
                <div>
                  {position > 0 && (
                    <NButton
                      outlined
                      disabled={isPendingLocal}
                      onClick={() => {
                        if (
                          questions.length > 0 &&
                          steps[position] === "question" &&
                          positionQuestion > 0
                        ) {
                          const positionX = positionQuestion - 1;
                          setPositionQuestion(positionX);
                          return null;
                        }

                        setPosition(position - 1);
                      }}
                      style={{ marginRight: "16px", fontWeight: "500" }}
                      color="second"
                      sizeStyle={ButtonSize.md}
                      border="24px"
                    >
                      {_.upperFirst(t("previous"))}
                    </NButton>
                  )}
                  <NButton
                    style={{ fontWeight: "500" }}
                    onClick={async () => {
                      await saveTask();
                    }}
                    disabled={isPendingLocal}
                    border="24px"
                    sizeStyle={ButtonSize.md}
                    loading={isPendingLocal}
                    color="second"
                  >
                    {_.upperFirst(t("save"))}
                  </NButton>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ResultCall;
