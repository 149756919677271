import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { CardNimbi, DividerH } from "components/Nimbiv2/styled";
import { EmptyContainer } from "containers/Help";

const Glossary = ({ items, search, title }) => {
  useEffect(() => {
    setListGlosary(items);
  }, [items]);

  const [listGlosary, setListGlosary] = useState(items);

  useEffect(() => {
    if (search && search !== "") {
      let newListRadios = items.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      );
      setListGlosary(newListRadios);
    } else {
      setListGlosary(items);
    }
  }, [search, setListGlosary, items]);

  if (listGlosary?.length === 0) {
    return <EmptyContainer />;
  }
  return (
    <CardNimbi padding="24px" style={{ height: "auto", borderRadius: "8px" }}>
      <TitleHelp>{title ?? "Glosario"}</TitleHelp>
      <DividerH style={{ margin: "20px 0px", color: "#DDE3EC66" }} />
      {listGlosary.map((item, key) => {
        return (
          <ItemFAQ key={`glosary-item-${key}`}>
            <div>
              <strong>{item.title}:</strong> {item.description}
            </div>
          </ItemFAQ>
        );
      })}
    </CardNimbi>
  );
};

export default Glossary;
const TitleHelp = styled.div`
  color: var(--Dark-color, #081735);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

const ItemFAQ = styled.div`
  padding: 10px 0px 10px 0px;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: 0px;
  }
  .title-center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-lg);
    text-align: center;
  }
`;
