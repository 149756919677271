import _ from "lodash";
import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { CardNimbi } from "components/Nimbiv2/styled";
import { Container } from "react-bootstrap";
import {
  getSessionFilters,
  getDataFromLocalStorage,
  setDataToLocalStorage,
  setSessionFilters,
  APP_MODULES,
} from "utils";
import { markMoment } from "store/actions/crm.actions";
import { fetchClientFilters } from "store/actions/async/site-async.action";
import {
  getTaskList,
  fetchMoments,
  fetchStudentResponses,
  fetchCallResults,
} from "store/actions/async/crm-async.action";
import TaskFilters from "./TaskFilters";
import { TaskComponents } from "containers";
import { typesTaskEvent } from "utils";
import { dispatchTaskEvent } from "services/crmService";
import { toggleActionRow } from "store/actions/crm.actions";
import { PusherContext } from "store/pusher";

import TableTask from "./TableTask";
import { fetchInitiativeCallActive } from "store/actions/async/initiatives-async.actions";
import { getTabSelected } from "utils/tasks";
import { markInitiative } from "store/actions/initiative.actions";
import { filterMomentSelectActive } from "utils/moments";
import { permissionChecker } from "routes/helpers";
import { ContainerEmpty } from "components/Nimbiv2";
import { useTranslation } from "react-i18next";
import EmptyListSVG from "assets/images/EmptyListSVG";
const TaskView = () => {
  const { lastNotification } = useContext(PusherContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isIdle = useRef(true);
  const avoidReloadTasks = getDataFromLocalStorage("avoidReloadTasks", false);
  const queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);

  const {
    moments,
    singleLoads: { moments: singleMomentsLoaded },
    pending: { list: isPending },
    isMomentPending,
    tabTask: tab,
  } = useSelector((state) => state.crm, _.isEqual);

  const {
    initiativesCall,
    pending: { initiativesCall: isPendingInitiatives },
  } = useSelector((state) => state.initiative);

  const tasks = useSelector((state) => state.crm.tasks, _.isEqual);
  const [selectedM, setSelectedM] = useState(null);

  const momentListGlobal = useMemo(() => {
    return moments
      ? moments.filter((item) => item.active_student_tracking_count !== 0)
      : [];
  }, [moments]);

  const [callInitiativeList, setCallInitiativeList] = useState(0);

  const loadTasks = useCallback(() => {
    const momentList = filterMomentSelectActive(moments ?? []);

    if (
      isIdle.current &&
      !avoidReloadTasks &&
      (momentList.length > 0 || initiativesCall.length > 0)
    ) {
      const queryParams = getSessionFilters(APP_MODULES.TASK.FILTER_NAME, true);
      const taskFilters = getDataFromLocalStorage(APP_MODULES.TASK.FILTER_NAME);

      let finalParams = {};
      if (getTabSelected() === "initiatives") {
        if (initiativesCall.length > 0) {
          isIdle.current = false;
          let initiative_id = taskFilters?.initiative_id;
          finalParams = {};
          if (!initiative_id) {
            initiative_id =
              initiativesCall.length > 0 ? initiativesCall[0].id : null;
          }
          if (initiative_id) {
            dispatch(markInitiative(Number(initiative_id)));
            dispatch(
              fetchClientFilters({
                initiative_id: initiative_id,
                module: "tasks",
              })
            );
          }
          finalParams = {
            initiative_id: initiative_id ?? "",
            source: "initiative",
          };
        }
      } else {
        if (momentList.length > 0) {
          isIdle.current = false;
          let momentId = taskFilters?.student_tracking__momenttracking__moment;

          if (!momentId) {
            const itemsFiltered = momentList.filter(
              (element) => element.name.toLowerCase() === "momento 1"
            );
            if (itemsFiltered.length > 0 || momentList.length > 0) {
              momentId =
                itemsFiltered.length > 0
                  ? itemsFiltered[0].id
                  : momentList[0].id;
            }
          }
          if (momentId) {
            dispatch(markMoment(momentId));
            dispatch(
              fetchClientFilters({ moment_id: momentId, module: "tasks" })
            );
          }

          finalParams = {
            moment: momentId ?? "",
            student_tracking__momenttracking__moment: momentId ?? "",
            student_tracking__momenttracking__state: "active",
          };
        }
      }
      if (isIdle.current === false) {
        setSessionFilters(APP_MODULES.TASK.FILTER_NAME, {
          ...queryParams,
          ...finalParams,
        });
        // Duplicated call in TaskFilters.jsx
        dispatch(getTaskList({ ...queryParams, ...finalParams }));
      }
    }
    setDataToLocalStorage("avoidReloadTasks", false);
  }, [avoidReloadTasks, dispatch, initiativesCall, moments]);

  const loadMoments = useCallback(() => {
    if (!singleMomentsLoaded) {
      dispatch(fetchMoments());
    }
    const { groups = [] } = getDataFromLocalStorage("user", {});
    const access = permissionChecker({
      allowedCode: "initiatives_tasks",
      userRoles: groups,
    });

    if (access && callInitiativeList === 0) {
      dispatch(fetchInitiativeCallActive());
      setCallInitiativeList(1);
    }
  }, [dispatch, singleMomentsLoaded, callInitiativeList]);

  useEffect(() => {
    loadMoments();
  }, [loadMoments]);

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  const processTaskRequest = async (row, action) => {
    const payload = {
      action,
      channel: process.env.REACT_APP_PUSHER_CHANNEL,
      event: process.env.REACT_APP_PUSHER_EVENT,
    };

    try {
      const {
        data: { result: response },
      } = await dispatchTaskEvent(row.id, payload, row);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.resolve(false);
    }
  };

  const toggleTasks = async (action, item) => {
    if (action === typesTaskEvent.RELEASED) {
      await processTaskRequest(item, typesTaskEvent.RELEASED);
    }

    // lock/unlock others tasks
    dispatch(
      toggleActionRow({ taskId: item.id, lastTaskEvent: lastNotification })
    );
  };

  useEffect(() => {
    if (getTabSelected() === "initiatives") {
      if (initiativesCall && initiativesCall.length > 0) {
        let selectItem =
          initiativesCall.find((moment) => moment.selected) ?? null;
        if (queryParams && queryParams.initiative && selectItem === null) {
          dispatch(markMoment(queryParams.initiative));
        } else {
          if (!_.isEqual(selectedM, selectItem)) {
            setSelectedM(selectItem);
          }
        }
      }
    } else {
      if (moments && moments.length > 0) {
        let selectItem = moments.find((moment) => moment.selected) ?? null;
        if (queryParams && queryParams.moment && selectItem === null) {
          dispatch(markMoment(queryParams.moment));
        } else {
          if (!_.isEqual(selectedM, selectItem)) {
            setSelectedM(selectItem);
          }
        }
      }
    }
  }, [dispatch, moments, queryParams, initiativesCall, selectedM]);

  useEffect(() => {
    if (selectedM) {
      const user = getDataFromLocalStorage("user", {});
      if (
        user["config"] &&
        user["config"]["call_task_version"] &&
        user["config"]["call_task_version"] === "version2"
      ) {
        // When configured with version 2, endpoints will not be called.
      } else {
        if (getTabSelected() === "initiatives") {
          dispatch(
            fetchStudentResponses({
              initiative: selectedM.id,
              page_size: 20,
              page: 1,
            })
          );
          dispatch(
            fetchCallResults({
              initiative: selectedM.id,
              page_size: 20,
              page: 1,
            })
          );
        } else {
          dispatch(
            fetchStudentResponses({
              moment: selectedM.id,
              page_size: 20,
              page: 1,
            })
          );
          dispatch(
            fetchCallResults({ moment: selectedM.id, page_size: 20, page: 1 })
          );
        }
      }
    }
  }, [dispatch, selectedM]);

  return (
    <Container fluid style={{ paddingBottom: "20px" }}>
      <TaskFilters />

      <CardNimbi padding="0px 16px 16px 16px" style={{ height: "auto" }}>
        <TableTask
          key={"table-task"}
          dataSource={tasks}
          isPending={isPending || isMomentPending || isPendingInitiatives}
          collapse
          toggleTasks={toggleTasks}
          processTaskRequest={processTaskRequest}
          showEmptyWidget={
            tab === "moments"
              ? momentListGlobal && momentListGlobal.length > 0
              : initiativesCall && initiativesCall.length > 0
          }
        />
      </CardNimbi>

      {!isMomentPending &&
        ((tab === "moments" && momentListGlobal.length === 0) ||
          (tab === "initiatives" && initiativesCall.length === 0)) && (
          <CardNimbi padding="16px 16px 16px 16px" style={{ height: "auto" }}>
            <div className="pb-5">
              <ContainerEmpty
                version="2"
                text={
                  tab === "initiatives"
                    ? t("you_have_no_initiative")
                    : t("you_have_no_action_plans")
                }
                image={<EmptyListSVG />}
                description={
                  tab === "initiatives" ? (
                    <div style={{ marginTop: "5px" }}>
                      Aquí se mostrarán las tareas de tu plan de acción <br />{" "}
                      cuando estén disponibles
                    </div>
                  ) : (
                    <div style={{ marginTop: "5px" }}>
                      Aquí se mostrarán las tareas de tus compañas <br /> cuando
                      estén disponibles
                    </div>
                  )
                }
              />
            </div>
          </CardNimbi>
        )}

      {((tab === "moments" && momentListGlobal.length > 0) ||
        (tab === "initiatives" && initiativesCall.length > 0)) && (
        <TaskComponents.PaginationBar />
      )}
    </Container>
  );
};

export default TaskView;
