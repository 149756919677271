import { generateUrlWithParams } from "utils";
import api from "./api";

const getTasks = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    "crm/task/without-moment/",
    queryParams
  );
  return api.get(finalUrl);
};

const getInsights = () => {
  return api.get("crm/task/insights/");
};

const getInsightsType = (url) => {
  return api.get(`crm/task/${url}/`);
};

const getTopCallers = (queryParams) => {
  const finalUrl = generateUrlWithParams(
    "crm/task/top-callers-list/",
    queryParams
  );
  return api.get(finalUrl);
};

const getTrackingTypes = () => {
  return api.get('crm/task/insights-tracking')
}

export { getTasks, getInsights, getInsightsType, getTopCallers, getTrackingTypes };
